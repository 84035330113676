
.blog-asym-grid {
    @include list-reset;

    display: grid;
    gap: $spacing*2;

    @include screen($bp992) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: $spacing*3;
    }

    @include screen($bp1400) {
        gap: $spacing*4;
    }


    &__item {

        @include screen($bp992) {
            &--first {
                grid-row: -1 / 1;
            }
        }

    }
}

.alt-feat-box {
    --tb-padding: #{$spacing*3};
    --lr-padding: #{$spacing*2.5};

    @include screen($bp600) {
        --lr-padding: #{$spacing*3.5};

    }


    @include screen($bp992) {
        --tb-padding: #{$spacing*4};
    }

    display: block;
    background-color: $color-grey-02;
    color: inherit;
    text-decoration: none;
    display: grid;
    grid-template-columns: 25% auto;
    height: 100%;
    transition: background $link-transition;

    &:hover,
    &:focus,
    &:active {
        color: inherit;
        background-color: lighten($color-grey-02, 5%);
    }

    &__image {
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .5s ease-in-out;
        }
    }
    &:hover img,
    &:focus img,
    &:active img {
        transform: scale(1.1);
    }

    &__text {
        position: relative;
        padding: var(--tb-padding) var(--lr-padding);
        display: grid;
        align-content: space-between;

        *:last-child {
            margin-bottom: 0;
        }
    }

    &__intro {
        @include screen($bp992) {
            overflow: hidden;
            display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
        }

    }

    &__title {
        @include screen($bp1200) {
            font-size: 2.8rem;
        }

    }

    &__date {
        position: absolute;
        right: calc(100% - $spacing*1.5);
        display: block;
        background-color: $color-alternative;
        color: $color-white;
        text-align: center;
        padding: 4px;
        line-height: 1.1;
        text-transform: uppercase;
        width: 40px;
        height: 46px;

        @include screen($bp600) {
            right: calc(100% - $spacing*1.75);
            top: var(--lr-padding);
        }

        @include screen($bp992) {
            width: 58px;
            height: 58px;
        }
        @include screen($bp1200) {
            margin-top: 4px;
        }


        &__day {
            display: block;
            font-size: 2rem;

            @include screen($bp992) {
                font-size: 2.8rem;
            }
        }

        &__month {
            display: block;
            font-size: 1.2rem;

            @include screen($bp992) {
                font-size: 1.3rem;
            }
        }
    }

    .button {
        margin-top: 0 !important;
    }
}

.alt-feat-box {
    &--card {
        grid-template-columns: unset;
        display: flex;
        flex-flow: column nowrap;
    }
    &--card &__image {
        aspect-ratio: 6 / 3;
    }
    &--card &__date {
        top: calc(#{$spacing} * -2);
        left: var(--lr-padding);
        width: 58px;
        height: 58px;

        @include screen($bp992) {
            width: 84px;
            height: 84px;
            padding-top: 7px;
            margin-top: -1px;
        }

        &__day {
            font-size: 2.8rem;
            @include screen($bp992) {
                font-size: 3.8rem;
            }

        }
        &__month {
            font-size: 1.3rem;
            @include screen($bp992) {
                font-size: 2rem;
            }

        }

    }
    &--card &__text {
        padding-top: calc(var(--tb-padding) * 2);

        flex: 1 0 auto;
        display: grid;
        align-content: end;
        @include screen($bp992) {
            padding-top: calc(var(--tb-padding) * 2.5);

        }

    }

}
