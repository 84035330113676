

.feature-item {
    --cta-height: #{$spacing*5};

    display: block;
    height: auto;
    color: inherit;
    text-decoration: none;
    background-color: $color-grey-01;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    @include screen($bp1200) {
        --cta-height: #{$spacing*7};

        flex: 1 0 auto;
    }


    &:hover,
    &:focus,
    &:active {
        color: inherit;
    }

    .bg-lightgrey & {
        background-color: darken($color-grey-01, 5%);
    }

    &__deco-line {
        position: absolute;
        top: $spacing*1.75;
        bottom: $spacing*1.75;
        left: $spacing*1.75;
        right: $spacing*1.75;
        z-index: 1;
        border: 1px solid $color-grey-02;

        .bg-lightgrey & {
            border-color: darken($color-grey-02, 5%);
        }

        @include screen($bp1300) {
            top: $spacing*2;
            bottom: $spacing*2;
            left: $spacing*2;
            right: $spacing*2;
        }

    }

    &__img {
        position: relative;
        z-index: 0;
        aspect-ratio: 1080 / 520;
    }

    &__textarea {
        background-color: inherit;
        position: relative;
        padding: $spacing*2 $spacing*3.5 $spacing*3.5;
        margin-top: 0;
        transition: margin $link-transition, padding $link-transition;
        flex: 1 0 auto;
        display: flex;
        flex-flow: column nowrap;

        @include screen($bp1300) {
            padding: $spacing*2 $spacing*4 $spacing*4;
        }


        *:last-child {
            margin-bottom: 0;
        }
    }
    @include screen($bp1200) {
        &:hover &__textarea,
        &:focus &__textarea,
        &:active &__textarea {
            margin-top: calc(-1 * var(--cta-height));
        }
    }


    &__cta {
        background-color: $color-primary;
        color: $color-white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: var(--h4-font-size);
        display: grid;
        justify-content: center;
        align-items: center;
        padding: $spacing;
        padding: 0;
        height: var(--cta-height);
        margin: $spacing*2 0 0;
        overflow: hidden;
        transition: height $link-transition, opacity $link-transition;

        @include screen($bp1200) {
            height: 0;
            opacity: 0;
            margin-top: $spacing*.5;
        }

    }
    &:hover &__cta,
    &:focus &__cta,
    &:active &__cta {
        opacity: 1;
        height: var(--cta-height);
    }

    &__title {
        font-size: var(--h2-font-size);
    }

    &__subtitle {
        text-transform: uppercase;
        font-weight: bold;
        flex: 1 0 auto;
    }

    &__price-text {
        &__price {
            font-weight: bold;
            font-size: var(--h3-font-size);
        }
    }

    &__subtle-subtitle {
        @include subtitle;
    }
}

.feature-item {
    &--button {
        display: grid;
        justify-content: end;
        align-items: space-between;
        position: relative;
        transition: background $link-transition, color $link-transition;
        font-size: var(--h3-font-size);
        text-transform: uppercase;
        background-color: $color-primary;
        color: $color-accent-01;

        &::after {
            content: '';
            position: absolute;
            top: $spacing*2;
            bottom: $spacing*2;
            left: $spacing*2;
            right: $spacing*2;
            border: 1px solid $color-accent-01;
            transition: border $link-transition;

        }
        &:hover::after,
        &:focus::after,
        &:active::after {
            // border-width: 2px;
            border-color: $color-white;
        }
    }

    &--button:hover,
    &--button:focus,
    &--button:active {
        color: $color-white;
    }

    &--button &__textarea {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: $spacing*4.5 $spacing*5;
    }
}

.feature-item {
    &--journeys {

    }
}

.journeys-slider-wrap {
    position: relative;
}

.journeys-slider-arrows {
    position: absolute;
    z-index: 10;
    top: calc(50% - 40px);
    left: calc(-.75 * var(--slick-arrow-width));
    right: calc(-.75 * var(--slick-arrow-width));

    @include screen($bp600) {
        left: calc(-1.5 * var(--slick-arrow-width));
        right: calc(-1.5 * var(--slick-arrow-width));
    }

    @include screen($bp769) {
        left: calc(-1.12 * var(--slick-arrow-width));
        right: calc(-1.12 * var(--slick-arrow-width));
    }

    @include screen(50em) { /* 800px */
        left: calc(-1.5 * var(--slick-arrow-width));
        right: calc(-1.5 * var(--slick-arrow-width));
    }

    @include screen($bp992) {
        left: calc(-1.25 * var(--slick-arrow-width));
        right: calc(-1.25 * var(--slick-arrow-width));
    }

    @include screen($bp1600) {
        left: calc(-2 * var(--slick-arrow-width));
        right: calc(-2 * var(--slick-arrow-width));
    }

}

.journeys-slider {
    --j-slider-gap: #{$spacing};
    overflow: hidden;

    @include screen($bp992) {
        --j-slider-gap: #{$spacing*2};
    }

    &.slick-slider .slick-track {
        display: flex !important;

        .slick-slide {
            display: flex !important;
            flex-direction: column !important;
            height: auto !important;
        }
    }

    .slick-list {

    }

    @include screen($bp769) {
        .slick-list {
            margin: 0 calc(-1 * #{var(--j-slider-gap)});
        }

        &__item {
            padding: 0 var(--j-slider-gap);
        }

    }

}
