@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/fonts/icomoon.eot?ir4523');
    src:  url('../fonts/icomoon/fonts/icomoon.eot?ir4523#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?ir4523') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?ir4523') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?ir4523#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-before:before,
.icon-after:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.icon-before.icon-download:before,
.icon-after.icon-download:after {
    content: "\e915";
}
.icon-before.icon-list:before,
.icon-after.icon-list:after {
  content: "\e913";
}
.icon-before.icon-location:before,
.icon-after.icon-location:after {
  content: "\e914";
}
.icon-before.icon-desktop:before,
.icon-after.icon-desktop:after {
  content: "\e910";
}
.icon-before.icon-email:before,
.icon-after.icon-email:after {
  content: "\e911";
}
.icon-before.icon-phone:before,
.icon-after.icon-phone:after {
  content: "\e912";
}
.icon-before.icon-zoom-in:before,
.icon-after.icon-zoom-in:after {
    content: "\e90e";
}
.icon-before.icon-zoom-out:before,
.icon-after.icon-zoom-out:after {
    content: "\e90f";
}
.icon-before.icon-check:before,
.icon-after.icon-check:after {
    content: "\e90d";
}
.icon-before.icon-arrow-down:before,
.icon-after.icon-arrow-down:after {
    content: "\e900";
}
.icon-before.icon-arrow-right:before,
.icon-after.icon-arrow-right:after {
    content: "\e901";
}
.icon-before.icon-arrow-up:before,
.icon-after.icon-arrow-up:after {
    content: "\e902";
}
.icon-before.icon-arrow-left:before,
.icon-after.icon-arrow-left:after {
    content: "\e903";
}
.icon-before.icon-binoculars:before,
.icon-after.icon-binoculars:after {
    content: "\e904";
}
.icon-before.icon-facebook:before,
.icon-after.icon-facebook:after {
    content: "\e905";
}
.icon-before.icon-globe:before,
.icon-after.icon-globe:after {
    content: "\e906";
}
.icon-before.icon-growth:before,
.icon-after.icon-growth:after {
    content: "\e907";
}
.icon-before.icon-home:before,
.icon-after.icon-home:after {
    content: "\e908";
}
.icon-before.icon-instagram:before,
.icon-after.icon-instagram:after {
    content: "\e909";
}
.icon-before.icon-pinterest:before,
.icon-after.icon-pinterest:after {
    content: "\e90a";
}
.icon-before.icon-twitter:before,
.icon-after.icon-twitter:after {
    content: "\e90b";
}
.icon-before.icon-youtube:before,
.icon-after.icon-youtube:after {
    content: "\e90c";
}

.icon-before {
    &.icon-handshake-global:before {
        content: "\e916";
    }
    &.icon-handshake-plant:before {
        content: "\e917";
    }
    &.icon-handshake:before {
        content: "\e918";
    }
}

.icon-after {
    &.icon-handshake-global:after {
        content: "\e916";
    }
    &.icon-handshake-plant:after {
        content: "\e917";
    }
    &.icon-handshake:after {
        content: "\e918";
    }
}
