
.full-width-img-slider {
    @include list-reset;
    margin: 0;
    --content-padding: #{$spacing*2.5};

    @include screen($bp767) {
        --content-padding: #{$spacing*6};
    }
    @include screen($bp1200) {
        --content-padding: #{$spacing*8};
    }

    &__item {
        position: relative;
        overflow: hidden;
        background-color: $color-black;

        padding: var(--content-padding) 0;


        &__img {
            background: no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: .7;
        }

        .container {
            display: grid;
            justify-content: center;
            min-height: 400px;
            @include screen($bp1200) {
                min-height: 500px;
            }

        }

        &__content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-start;
            position: relative;
            z-index: 2;
            border: 1px solid #fff;
            border-right: 0;
            color: $color-white;
            background: linear-gradient(90deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.30) 50%, rgba(0,0,0,0) 100%);
            padding: var(--content-padding) 0 $spacing*8  var(--content-padding);

            @include screen($bp480) {
            }

            @include screen($bp767) {
                padding-bottom: var(--content-padding);
                font-size: 1.8rem;
                margin-left: calc(100% - 65ch);

                p {
                    margin-bottom: $spacing*2.5
                }
            }

            &__preheading {
                @include subtitle;
                font-size: 1.8rem;
                color: $color-grey-02;
            }


            &::before {
                content: '';
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: 100%;
                border: 1px solid #fff;
                border-left-width: 0;
                border-right-width: 0;
                width: 50vw;
            }

            &__heading {
                margin-top: 0;
                color: $color-white;
                font-size: 3rem;
                @include screen($bp767) {
                    font-size: 6rem;
                }
                @include screen($bp1200) {
                    font-size: 6.4rem;
                }

            }

        }
    }
    .slick-dots {
        bottom: calc(var(--content-padding) * 2);
        right: calc(6% + var(--content-padding));
        margin: 0;

        @include screen($bp767) {
            margin-bottom: $spacing*1.5;
        }

    }
}
