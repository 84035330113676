


.section--hero-slider {
    padding-top: 0;
    padding-bottom: 0;
}
.section--hero-banner {
    position: relative;

    & .container {
        z-index: 1;
    }
}

.hero-slider-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        --banner-height: 360px;

        @include screen($bp768) {
            --banner-height: 432px;
        }

        @include screen($bp992) {
            --banner-height: 540px;
        }

        @include screen($bp1300) {
            --banner-height: 720px;
        }

        min-height: var(--banner-height);
        overflow: hidden;
        position: relative;
        height: 100%;
        background-color: $color-black;

        &__content {
            height: calc(100% - var(--large-box-pad-vert)*2);
            display: grid;
            align-items: end;
            position: relative;
            border: 1px solid $color-grey-02;
            border-bottom: 0;

            @include large-box-padding;
            margin-top: var(--large-box-pad-vert);

            @include screen($bp768) {
                height: calc(100% - var(--large-box-pad-vert)*1.5);
            }

            @include screen($bp1300) {
                height: calc(100% - var(--large-box-pad-vert));
            }
        }

        &__img {
            background: center center no-repeat;
            background-size: cover;

            opacity: .90;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            animation: kenBurns linear 32s infinite;

            .slick-slide:nth-child(4n+1) & {
                transform-origin: bottom left;
            }

            .slick-slide:nth-child(4n+2) & {
                transform-origin: top right;
            }

            .slick-slide:nth-child(4n+3) & {
                transform-origin: center left;
            }

            .slick-slide:nth-child(4n+4) & {
                transform-origin: center right;
            }

            &::after {
                content: '';
                position: absolute;
                top: 80%;
                bottom: 0;
                left: 0;
                right: 0;
                background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 98%);
            }
        }

        &__title {
            font-size: 2rem;
            padding-left: .5em;
            margin: 0 0 20px;
            line-height: 1.25;
            position: relative;
            text-shadow: 0 0 10px rgba($color-black, .5);
            z-index: 2;

            @include screen($bp480) {
                padding-left: 1em;
            }
            @include screen($bp992) {
                font-size: 3rem;
            }
            @include screen($bp1300) {
                font-size: 3.5rem;
            }

            &__deco-intro {
                font-size: 2em;
                line-height: 1;
                display: block;
                position: absolute;
                bottom: calc(100% - .18em);
                left: -.12em;
                font-family: 'Smooch';
                color: rgba($color-white, .8);
                mix-blend-mode: screen;
                z-index: -1;
                text-shadow: none;

                @include screen($bp480) {
                    font-size: 4em;
                }

            }

        }
    }
    .container--hero-slider-list {
        display: grid;
        height: calc(var(--banner-height));
        align-items: end;
    }
}

/* Js */
.hero-slider-list .slick-list {
    height: 100%;
    overflow: visible;
}
.hero-slider-list .slick-track {
    height: 100%;
}
.hero-slider-list .hero-slider-list__item {
    outline: none;
}

/* No js */
.no-js .hero-slider-list__item {
    display: none;
}
.no-js .hero-slider-list__item:first-child {
    display: block;
}

/* from price */
.hero-slider-list__item__from {
    font-family: $primary-font;
    font-size: 1.2rem;
    text-transform: uppercase;
}
.hero-slider-list__item__from__price {
    font-size: 1.8rem;
    display: inline-block;
    font-weight: bold;
}



/* BP 1 */
@media screen and (min-width: 600px) {

    /* from price */
    .hero-slider-list__item__from {
        font-size: 1.4rem;
        /* [02] */
    }
    .hero-slider-list__item__from__price {
        font-size: 2.2rem;
    }
}

/* BP 2 */
@media screen and (min-width: 769px) {

    .hero-slider-list__item__title,
    .hero-banner__title {
    }
    /* from price */
    .hero-slider-list__item__from {
        font-size: 1.6rem;
        /* [02] */
    }
}

/* BP 3 */
@include screen($bp992) {

    .hero-slider-list__item__title,
    .hero-banner__title {
    }


}

/* BP 4 */
@media screen and (min-width: 1300px) {

    .hero-slider-list__item__title,
    .hero-banner__title {
    }

}

