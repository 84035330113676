

@import '06-abstracts/_colors';

@import '06-abstracts/_breakpoint-mixins';

@import '06-abstracts/_fonts';

@import '06-abstracts/_animation';

@import '06-abstracts/_utilities';

/* ---- Mixins ---- */

@import "00-base/helpers/utility-mixins";


/* ------------------------------------------------------
Table of contents
---------------------------------------------------------

**** Notes

---- File upload area
---- File selector
---- Autocomplete
---- Lightweight elements
---- TinyMCE tweaks
---- Responsiveness
---- Submit Bar
---- Search Form
---- Search pagination
---- Captcha

**** Tables
---- Content tables
---- Responsive table
---- No Styles Table

**** Helper classes
---- Clearfix
---- Hiding classes
---- Pseudo elements
---- Floats and positioning
---- Text align
---- Grid system

**** Template Layout
---- Popup container
---- Left padded left
---- Text large
---- Subtitles
---- Sections
---- Section titles
---- Background colours
---- Subnav
---- Header
---- Sliders
---- Koala slider & banner
---- Section kangaroo
---- Tab navigation
---- Hover box list
---- Feature box list
---- Feature Box Companion Content
---- Map Banner
---- Popup boxes
---- Itinerary Item list
---- Outline box
---- Icon circles
---- Refine bar
---- Croc slider list
---- Pre footer
---- Footer
---- Content
---- Outline content
---- Image Title Content
---- Magnific Popup
---- Thumbnail slider
---- Operator map
---- Tour map
---- Switch tabs

**** Widgets
---- Related Links
---- Simple Image Gallery
---- Children Page Gallery
---- Sponsors module
---- Order Form
---- Videos
---- reCAPTCHA
---- Sidebar


**** Print styles

------------------------------------------------------ */

/* ------------------------------------------------------
**** Notes
---------------------------------------------------------
/* [01]
 - add popup container top offset to additional bottom padding on popup content to pevent premature content clipping

------------------------------------------------------ */


@-webkit-viewport {
	width: device-width;
	zoom: 1.0;
}
@-moz-viewport {
	width: device-width;
	zoom: 1.0;
}
@-ms-viewport {
	width: device-width;
	zoom: 1.0;
}
@viewport {
	width: device-width;
	zoom: 1.0;
}


/* ------------------------------------------------------
**** Box Model Reset
------------------------------------------------------ */

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

/* ------------------------------------------------------
**** Typography
------------------------------------------------------ */

// old icon-font that's still used.
@import '00-base/_icon-font';



html {
	font-size: 62.5%;
}

body {
	line-height: 1.4;
	font-size: 1.5em;
	color: $color-secondary;
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	body {
		font-size: 1.6em;
	}
}


@import '00-base/typography/typography-mixins';


/* ---- Headings ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: 0 0 1.3rem 0;
	font-family: $accent-font;
	font-weight: 300;
	line-height: 1.2;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
	font-weight: normal;
	line-height: 1;
	color: #8a7b7b;
}

p + h1,
p + .h1,
p + h2,
p + .h2,
p + h3,
p + .h3,
p + h4,
p + .h4,
p + h5,
p + .h5,
p + h6,
p + .h6 {
	margin-top: 30px;
}


h1, .h1 {
	font-size: var(--h1-font-size);
	line-height: var(--h1-line-height);
}

h2, .h2 {
	font-size: var(--h2-font-size);
	line-height: var(--h2-line-height);
	color: $color-secondary;
}

h3, .h3 {
	font-size: var(--h3-font-size);
	line-height: var(--h3-line-height);
	color: $color-secondary;
}

h4, .h4 {
	font-size: var(--h4-font-size);
	line-height: var(--h4-line-height);
}

h5, .h5 {
	font-size: var(--h5-font-size);
	line-height: var(--h5-line-height);
}


@include screen($bp600) {

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		margin: 0 0 1.5rem 0;
	}

}

/* BP 2 */
@include screen($bp992) {

	h2, .h2 {
		margin-bottom: 2rem;
	}

}


.heading-inline {
	display: inline;
	float: left;
	padding: 0;
}

.heading-orange {
	color: $color-primary;
}

p,
ol,
ul,
dl,
address {
	margin: 0 0 1.5rem;
}

small {
	font-size: 1.28rem;
}


/* ---- Lists ---- */
ul,
ol {
	padding: 0 0 0 2rem;
}

li ul,
li ol {
	margin: 1rem 0;
}

ul.inline,
ol.inline {
	list-style-type: none;
	margin-left: 0;
}
ul.inline > li,
ol.inline > li {
	display: inline-block;
	padding-left: 12px;
	padding-right: 12px;
}

/* Icon lists */
.icon-list {
	list-style: none;
	padding: 0;
	margin: 20px 0;
}
.icon-list li {
	margin: 0 0 14px;
	padding-left: 3rem;
	position: relative;
}
.icon-list li:last-child {
	margin-bottom: 0;
}
.icon-list li:before {
	content: " ";
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-size: 2.9rem;
	margin: 0;
	vertical-align: top;
	line-height: 1;
	position: absolute;
	left: -6px;
}

/* Green */
.icon-list.icon-list--green li:before {
	color: #6a7f3e;
}

/* Check */
.icon-list.icon-list--check li:before {
	content: "\e90d";
}

/* Column list */


.column-list {
	-webkit-columns: 250px 2;
	   -moz-columns: 250px 2;
	        columns: 250px 2;
}

.column-list li {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

.column-list li:before,
.column-list li:after {
	content: " ";
	display: table;
}
.column-list li:after {
	clear: both;
}



/* ---- Breadcrumbs ---- */
.breadcrumb {
	list-style-type: none;
	margin: 0 0 11px;
	padding: 0;
	font-size: 1.3rem;
	text-transform: uppercase;
}
.breadcrumb > li {
	display: inline-block;
}
.breadcrumb,
.breadcrumb a {
	color: $color-grey-03;
}
.breadcrumb a:hover {
	color: $color-secondary;
}
.breadcrumb a {
	display: inline-block;
	font-weight: bold;
	text-decoration: none;
}
.breadcrumb > li:before {
	content: "\00a0\003e\00a0";
	margin: 0;
}
.breadcrumb > li:first-child:before {
	content: none;
}
.breadcrumb > li:first-child a {
	text-indent: -9999px;
	position: relative;
	width: 21px;
}
.breadcrumb > li:first-child a:before {
	content: "\e908";
	text-indent: 0;
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 2.4rem;
	position: absolute;
	top: -3px;
	left: -2px;
	display: block;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.breadcrumb {
		font-size: 1.4rem;
	}
	.breadcrumb > li:first-child a {
		width: 22px;
	}
	.breadcrumb > li:first-child a:before {
		font-size: 2.6rem;
		top: -4px;
		left: -1px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.breadcrumb {
		margin: 0 0 20px;
		font-size: 1.6rem;
	}
	.breadcrumb > li:first-child a {
		width: 26px;
	}
	.breadcrumb > li:first-child a:before {
		font-size: 3rem;
		top: -5px;
		left: -1px;
	}
}

/* BP 3 */
@include screen($bp992) {

}

/* BP 4 */
@media screen and (min-width: 1300px) {

}


/* ---- Blockquotes ---- */
blockquote {
	margin: 1.5rem 0;
	padding: 5px 20px 15px;
	background: #f6f6f6;
	font-style: italic;
	position: relative;
	clear: both;
}

blockquote *:last-child {
	margin-bottom: 0;
}

blockquote:before,
blockquote:after {
	position: absolute;
	font-size: 13rem;
	display: block;
	height: 25px;
	width: 50px;
	color: #c7cfdb;
	font-style: italic;
	line-height: 10px;
	font-family: "Big Caslon","Book Antiqua","Palatino Linotype",Georgia,serif;
}

blockquote:before {
	content: '\201C';
	top: 38px;
	left: -8px;
}

blockquote:after {
	content: '\201D';
	bottom: -36px;
	right: 18px;
}

@include screen($bp992) {
	.blockquote--left {
		float: left;
		width: 45%;
		margin-left: 0px;
		margin-right: 20px;
		clear: right;
	}

	.blockquote--right {
		float: right;
		width: 45%;
		margin-left: 20px;
		margin-right: 0px;
		clear: left;
	}
}


/* ---- HR ---- */
hr {
	box-sizing: content-box;
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 2em 0;
	padding: 0;
}


/* ---- Text level elements ---- */
abbr[title] {
	border-bottom: 1px dotted #c6bfbf;
	cursor: help;
}

b, strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

ins {
	background-color: #f6f6f6;
	color: #473f3f;
	text-decoration: none;
}

mark {
	background-color: #f6f6f6;
	color: #473f3f;
	font-style: italic;
	font-weight: bold;
}

pre,
code,
kbd,
samp {
	font-family: Monaco, "Courier New", monospace;
	color: #484040;
	background: #efefef;
	background: rgba(0, 0, 0, .07);
	padding: 0 2px;
	margin: 0 -2px;
}

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}


/* ---- Links ---- */
a {
	color: $color-primary;
	word-break: break-word;
}

a:hover,
a:active,
a:focus {
	color: #b5520c;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
	outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
	outline: 0;
}

/* wrapper */
.subtle-links a {
	transition: all 150ms ease-in-out;
	text-decoration: none;
	border-bottom: 1px solid rgba(211,96,14,0);
}

.subtle-links a:hover,
.subtle-links a:focus,
.subtle-links a:active {
	text-decoration: none;
	border-bottom: 1px solid rgba(181,82,12,1);
}

/* individual link */
.subtle-link {
	transition: all 150ms ease-in-out;
	text-decoration: none;
	border-bottom: 1px solid rgba(181,82,12,1);
}

.subtle-link:hover,
.subtle-link:focus,
.subtle-link:active {
	text-decoration: none;
	border-bottom: 1px solid rgba(181,82,12,1);
}

/* ------------------------------------------------------
**** Design Patterns
------------------------------------------------------ */

/* ---- Buttons ---- */
.button {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	font-weight: normal;
	line-height: 1;
	background-color: transparent;
	text-decoration: none;
	border-radius: 0;
	border-width: 2px;
	border-style: solid;
	text-transform: lowercase;
	transition: all 120ms ease-in;
	outline: none;
	position: relative;
	z-index: 1;
}

/* More space around buttons after paragraphs */
p + p > .button:only-child {
	margin-top: 10px;
}

/* BP 2 */
@media screen and (min-width: 769px) {
	p + p > .button:only-child {
		margin-top: 20px;
	}
}


/* Buttons with icons */
.button.icon-before,
.button.button-small.icon-before {
	padding-left: 41px;
}
.button.icon-after,
.button.button-small.icon-after {
	padding-right: 41px;
}
.button.icon-before:before,
.button.icon-after:after,
.button.button-small.icon-before:before,
.button.button-small.icon-after:after {
	font-size: 26px;
	position: absolute;
	top: 5px;
}
.button.icon-before:before,
.button.button-small.icon-before:before {
	left: 9px;
}
.button.icon-after:after,
.button.button-small.icon-after:after {
	right: 9px;
}

/* Buttons with only icons */
.button.button-icon.icon-before:before,
.button.button-icon.icon-after:after {
	margin: 0;
}

/* Save */
.button.icon-save.icon-before:before,
.button.icon-save.icon-after:after {
	font-size: 13px;
}

/* Small */
.button.button-icon.button-small {
	padding: 10px 9px 8px;
}
.button.button-icon.button-small.icon-before:before,
.button.button-icon.button-small.icon-after:after {
	font-size: 14px;
}

.button,
.button.button-small {
	padding: 8px 16px 11px;
	font-size: 1.6rem;
}

/* Regular (default) */
.button.button-icon,
.button.button-icon.button-regular {
	padding: 9px 17px 10px;
}


.button.icon-before,
.button.button-small.icon-before {
	padding-left: 42px;
}
.button.button-icon.icon-before:before,
.button.button-icon.icon-after:after,
.button.button-icon.button-small.icon-before:before,
.button.button-icon.button-small.icon-after:after {
	font-size: 28px;
	top: 3px;
}
.button.button-icon.icon-before:before,
.button.button-icon.button-small.icon-before:before {
	left: 4px;
}
.button.button-icon.icon-after:after,
.button.button-icon.button-small.icon-after:after {
	right: 4px;
}



/* BP 1 */
@media screen and (min-width: 600px) {

	.button.button-icon,
	.button.button-icon.button-regular {
		padding: 11px 21px 14px;
	}
	.button.button-icon.icon-before:before,
	.button.button-icon.icon-after:after {
		font-size: 33px;
		top: 5px;
	}
	.button.button-icon.icon-before:before {
		left: 5px;
	}
	.button.button-icon.icon-after:after {
		right: 5px;
	}
	.button.icon-before {
		padding-left: 42px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {
	.button.button-icon,
	.button.button-icon.button-regular {
		padding: 13px 26px 15px;
	}
	.button.icon-before {
		padding-left: 54px;
	}
	.button.icon-after {
		padding-right: 44px;
	}
	.button.icon-before:before,
	.button.icon-after:after {
		font-size: 30px;
		top: 10px;
	}
	.button.icon-before:before {
		left: 11px;
	}
	.button.icon-after:after {
		right: 11px;
	}

	.button.button-icon.icon-before:before,
	.button.button-icon.icon-after:after {
		font-size: 41px;
	}
	.button.button-icon.icon-before:before {
		left: 6px;
	}
	.button.button-icon.icon-after:after {
		right: 6px;
	}
	.button.button-icon.icon-before:before,
	.button.button-icon.icon-after:after {
		font-size: 41px;
		top: 4px;
	}


	/* Regular (default) */
	.button,
	.button-regular {
		padding: 13px 27px 15px;
		font-size: 2.1rem;
	}


}

/* BP 4 */
@media screen and (min-width: 1300px) {
	/* Large */
	.button.button-large {
		font-size: 2.5rem;
		padding: 14px 26px 15px;
	}

	.button.button-large.icon-before {
		padding-left: 54px;
	}
	.button.button-large.icon-before:before,
	.button.button-large.icon-after:after {
		font-size: 34px;
		top: 10px;
	}
	.button.button-large.icon-before:before {
		left: 12px;
	}
	.button.button-large.icon-after:after {
		right: 11px;
	}

	/* Large */
	.button.button-icon.button-large {
		padding: 13px 13px 9px;
	}
	.button.button-icon.button-large.icon-before:before,
	.button.button-icon.button-large.icon-after:after {
		font-size: 25px;
	}

}




/* Outline grey (default) */
.button,
.button.button-outline-grey {
	border-color: $color-grey-03;
	color: $color-secondary;
}
.button.icon-before:before,
.button.icon-after:after,
.button.button-outline-grey.icon-before:before,
.button.button-outline-grey.icon-after:after {
	color: $color-grey-03;
}
.button:hover,
.button.button-outline-grey:hover,
.button:focus,
.button.button-outline-grey:focus {
	border-color: $color-grey-03;
	color: $color-secondary;
	background-color: rgba(0,0,0,0.04);
}

/* Outline white */
.button.button-outline-white,
.bg-black .button {
	border-color: #fff;
	color: #fff;
}
.button.button-outline-white.icon-before:before,
.button.button-outline-white.icon-after:after,
.bg-black .button.icon-before:before,
.bg-black .button.icon-after:after {
	color: #fff;
}
.button.button-outline-white:hover,
.button.button-outline-white:focus,
.bg-black .button:hover,
.bg-black .button:focus{
	border-color: #fff;
	color: #fff;
}

/* Outline darkgrey */
.button.button-outline-darkgrey,
.bg-darkgrey .button {
	border-color: $color-grey-05;
	color: #fff;
}
.button.button-outline-darkgrey.icon-before:before,
.button.button-outline-darkgrey.icon-after:after,
.bg-darkgrey .button.icon-before:before,
.bg-darkgrey .button.icon-after:after {
	color: #fff;
}
.button.button-outline-darkgrey:hover,
.button.button-outline-darkgrey:focus,
.bg-darkgrey .button:hover,
.bg-darkgrey .button:focus{
	border-color: $color-grey-05;
	color: #fff;
}

/* Outline orange */
.button.button-outline-orange,
.bg-orange .button {
	border-color: $color-accent-01;
	color: #fff;
}
.button.button-outline-orange.icon-before:before,
.button.button-outline-orange.icon-after:after,
.bg-orange .button.icon-before:before,
.bg-orange .button.icon-after:after {
	color: #fff;
}
.button.button-outline-orange:hover,
.button.button-outline-orange:focus,
.bg-orange .button:hover,
.bg-orange .button:focus{
	border-color: $color-accent-01;
	color: #fff;
}

/* Outline blue */
.button.button-outline-blue,
.bg-blue .button {
	border-color: #8ec3ff;
	color: #fff;
}
.button.button-outline-blue.icon-before:before,
.button.button-outline-blue.icon-after:after,
.bg-blue .button.icon-before:before,
.bg-blue .button.icon-after:after {
	color: #fff;
}
.button.button-outline-blue:hover,
.button.button-outline-blue:focus,
.bg-blue .button:hover,
.bg-blue .button:focus{
	border-color: #8ec3ff;
	color: #fff;
}

/* Green */
.button.button-green {
	background-color: #9bb763;
	color: #fff;
	border-color: #6a813b;
}
.button.button-green.icon-before:before,
.button.button-green.icon-after:after {
	color: #6a813b;
}
.button.button-green:hover,
.button.button-green:focus {
	background-color: #8ea958;
	color: #fff;
}

/* Red */
.button.button-red {
	background-color: #DC5959;
	color: #fff;
}
.button.button-red.icon-before:before,
.button.button-red.icon-after:after {
	color: #A22B2B;
}
.button.button-red:hover,
.button.button-red:focus {
	background-color: #CC4C4C;
	color: #fff;
}

/* Grey */
.button.button-grey {
	background-color: #D0D3DA;
	color: #24292f;
}
.button.button-grey.icon-before:before,
.button.button-grey.icon-after:after {
	color: #81848A;
}
.button.button-grey:hover,
.button.button-grey:focus {
	background-color: #c4c8d0;
	color: #24292f;
}

/* Blue */
.button.button-blue {
	border-color: #2A333E;
	background-color: #3663AF;
	color: #fff;
}
.button.button-blue.icon-before:before,
.button.button-blue.icon-after:after {
	color: #2A333E;
}
.button.button-blue:hover,
.button.button-blue:focus {
	background-color: #4D5765;
	color: #fff;
}

.button-block {
	display: block;
	width: 100%;
}

/* orange */
.button.button-orange {
	background-color: $color-accent-01;
	color: #fff;
	border-color: $color-primary;
}
.button.button-orange.icon-before:before,
.button.button-orange.icon-after:after {
	color: $color-primary;
}
.button.button-orange:hover,
.button.button-orange:focus {
	background-color: #e58943;
	color: #fff;
}

/* Hover/unhover states */
.button-hover-state {
	display: none;
}
.button:hover .button-hover-state {
	display: inline;
}
.button:hover .button-unhover-state {
	display: none;
}

/* Disabled/unavailable button style */
.button[disabled],
.button-disabled,
.button-disabled:hover,
.button-disabled:focus,
.button-disabled:active {
	border: none;
	background-image: none;
	opacity: 0.4;
	cursor: default;
	box-shadow: none;
}

/* Gets the buttons to line up with form inputs when you want them to */
.button.inline {
	margin-bottom: 0.75em;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

/* Inline buttons */
.inline-buttons .button {
	margin-right: 8px;
	float: left;
}
.inline-buttons .button:last-child {
	margin-right: 0;
}

/* right */
.inline-buttons.inline-buttons-right {
	text-align: right;
}
.inline-buttons.inline-buttons-right .button {
	margin-right: 0;
	margin-left: 8px
}
.inline-buttons.inline-buttons-right .button:first-child {
	margin-left: 0;
}

/* ---- Expando ---- */
.expando-open--heading {
	font-family: $accent-font;
	color: #e85505;
}

.expando-open--heading:hover {
	color: #ae550d;
}


/* ---- Highlight ---- */
.highlight,
.highlight--right,
.highlight--left {
	margin: 0 0 1.5rem;
	padding: 14px 18px;
	background: #f6f6f6;
	clear: both;
}

@include screen($bp992) {
	.highlight,
	.highlight--right,
	.highlight--left {
		padding: 30px;
	}

	.highlight--left {
		float: left;
		width: 45%;
		margin-right: 20px;
		clear: right;
	}

	.highlight--right {
		float: right;
		width: 45%;
		margin-left: 20px;
		clear: left;
	}
}

.highlight > *:last-child,
.highlight--right > *:last-child,
.highlight--left > *:last-child {
	margin-bottom: 0;
}


/* ---- Site Messages ---- */
ul.messages {
	list-style: none;
	margin: 16px 0 25px;
	padding: 0;
}
ul.messages li {
	margin: 0 0 15px;
	padding: 16px 18px 17px;
	vertical-align: bottom;
	text-align: left;
	font-size: 1.6rem;
	line-height: 1.2;
	font-weight: bold;
	text-transform: uppercase;
	font-family: $primary-font;
}
ul.messages .error {
	background: #eec4c4;
	color: #7f3e3e !important;
}
ul.messages .confirm {
	color: #6a7f3e !important;
	background: #e0eec4;
}
ul.messages a {
	color: #FFF;
}

/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(40px);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeOutDown {
	from {
		opacity: 1;
		transform: none;
	}
	to {
		opacity: 0;
		transform: translateY(40px);
	}
}

@keyframes fadeInGrow {
	from {
		opacity: 0;
		transform: scale(0.5);
	}
	to {
		opacity: 1;
		transform: none;
	}
}
@keyframes fadeInShrink {
	from {
		opacity: 0;
		transform: scale(1.5);
	}
	to {
		opacity: 1;
		transform: none;
	}
}


/* ------------------------------------------------------
**** Images
------------------------------------------------------ */

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

/* ------------------------------------------------------
**** Forms
------------------------------------------------------ */

/* ---- Base styles ---- */
.field-element {
	margin-bottom: 21px;
}

.field-element:before,
.field-element:after {
	content: " ";
	display: table;
}

.field-element:after {
	clear: both;
}

.field-input .textbox,
.field-input select {
	display: block;
	width: 100%;
	outline: none;
	line-height: normal;
	padding: 13px 19px 13px;
	-webkit-appearance: none;
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
	font-size: 1.5rem;
	line-height: 1.2;
	border: none;
	border-radius: 0;
	background-color: $color-grey-03;
	color: $color-secondary;
}

/* ---- Placeholder ---- */
::-webkit-input-placeholder {
	color: #6e7977;
}

:-moz-placeholder { /* Firefox 18- */
	color: #6e7977;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #6e7977;
}

:-ms-input-placeholder {
	color: #6e7977;
}

.field-element--select--placeholder select {
	color: #6e7977;
}

/* ---- Uppercase ---- */
.field-element--uppercase .textbox,
.field-element--uppercase select {
	text-transform: uppercase
}

/* ---- Inline ---- */

/* Button */
.field-elements-inline .field-element--button {
	margin-top: 29px;
}
.field-elements-inline .field-element--button.field-element--hidden-label {
	margin-top: 0;
}
.field-elements-inline .field-element--button .button {
	padding-top: 5px;
	padding-bottom: 7px;
}
.field-element--button {
	text-align: right;
}

/* Add top margin to make empty space for margins */
.field-element--inline-with-label {
	margin-top: 29px;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.field-elements-inline .field-element--button .button {
		padding-top: 4px;
		padding-bottom: 6px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {
	.field-elements-inline .field-element--button .button {
		padding-top: 9px;
		padding-bottom: 10px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {

}

/* ---- Padding ---- */
.field-element--money .field-input .textbox,
.field-element--timepicker .field-input .textbox,
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox {
	padding-left: 60px;
}

/* ---- White ---- */
.field-element.field-element--white .field-input .textbox,
.field-element.field-element--white .field-input select,
.field-element--white.field-element--fileselector .fs-preview-wrapper {
	background-color: #fff;
}


/* ---- Small ---- */
.field-element--small .field-input .textbox,
.field-element--small .field-input select {
	font-size: 1.6rem;
	padding: 7px 15px 6px;
}
/* Padding */
.field-element--small.field-element--money .field-input .textbox,
.field-element--small.field-element--timepicker .field-input .textbox,
.field-element--small.field-element--datepicker .field-input .textbox,
.field-element--small.field-element--daterangepicker .field-input .textbox,
.field-element--small.field-element--datetimepicker .field-input .textbox,
.field-element--small.field-element--datetimerangepicker .field-input .textbox {
	padding-left: 55px;
}

/* ---- Large ---- */
.field-element--large .field-input .textbox,
.field-element--large .field-input select {
	font-size: 1.8rem;
	padding: 14px 21px 13px;
}
/* Padding */
.field-element--large.field-element--money .field-input .textbox,
.field-element--large.field-element--timepicker .field-input .textbox,
.field-element--large.field-element--datepicker .field-input .textbox,
.field-element--large.field-element--daterangepicker .field-input .textbox,
.field-element--large.field-element--datetimepicker .field-input .textbox,
.field-element--large.field-element--datetimerangepicker .field-input .textbox {
	padding-left: 65px;
}

@media screen and (max-width: 768px) {
	.field-element {
		margin-bottom: 13px;
	}
}

/* ---- Disabled ---- */
.field-element--disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled] {
	cursor: not-allowed;
}

/* ---- Caps ---- */
.field-element--caps .textbox,
.field-element--caps select {
	text-transform: uppercase;
}

/* ---- Fieldset ---- */
.field-element fieldset {
	border: none;
	padding: 0;
	margin: 0;
}


/* ---- Labels and legends ---- */
.field-label,
.fieldset__legend {
	font-weight: 600;
	font-size: 1.6rem;
	margin-bottom: 10px;
	line-height: 1.2;
	font-family: $primary-font;
}

/* Instead of h3s */
.label.heading {
	margin: 30px 0px 20px;
	padding: 8px 8px 8px 0px;
	border-bottom: 1px solid #E2E2E2;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


/* ---- Required ---- */
.field-label__required {
	text-indent: -9999px;
	line-height: 0;
	display: inline-block;
}

.field-label__required:after {
	content: "*";
	text-indent: 0;
	display: block;
	line-height: inherit;
}


/* ---- Field helpers ---- */
.field-helper {
	font-weight: 400;
	font-size: 1.3rem;
	margin-bottom: 1em;
}

.field-label .field-helper {
	margin-top: 0.3em;
	margin-bottom: 0;
}


/* ---- Field errors ---- */
.field-element.field-element--error  .field-input .textbox,
.field-element.field-element--error  .field-input select {
	background-color: #f7eaec;
}
.field-error__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.field-error__list__item {
	color: #fff;
	background: #E04D4D;
	padding: 10px 20px;
	margin: 11px 0;
	border-left: 6px solid #b72323;
}

/* ---- Text area ---- */
textarea {
	resize: vertical;
}

/* ---- Range ---- */
.field-element--range .field-input .textbox {
	-webkit-appearance: slider-horizontal;
	background: none;
	padding: 0;
	border-radius: 0;
	border: none;
	background: none !important;
}
input[type=range]::-moz-focus-outer {
	border: 0;
}


/* ---- Selects ---- */
.field-element .field-input select {
	-moz-appearance: none;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -256px right -104px, top -447px right -90px;
	padding-right: 65px;
}
.field-element .field-input select[multiple] {
	background-image: none;
	padding-right: 20px;
}

.field-element select::-ms-expand,
.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
	display: none;
}

/* Multiple selects */
.field-element.field-element--select.field-element--select--multiple select {
	padding: 8px 8px;
	background-image: none;
}

.field-element.field-element--select.field-element--select--multiple option {
	padding: 6px 10px;
}

/* White */
.field-element--white .field-input select {
	background-position: top -1806px right -104px, top -607px right -90px;
}

/* White + Small */
.field-element--small.field-element--white .field-input select {
	background-position: top -1812px right -104px, top -607px right -92px;
}
/* White + Large */
.field-element--large.field-element--white .field-input select {
	background-position: top -1802px right -100px, top -607px right -82px;
}

/* Small */
.field-element--small .field-input select {
	background-position: top -263px right -105px, top -447px right -92px;
}
/* Large */
.field-element--large .field-input select {
	background-position: top -254px right -100px, top -447px right -82px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element .field-input select,
	.field-element.field-element--large .field-input select,
	.field-element.field-element--regular .field-input select {
		background-position: top -262px right -104px, top -447px right -92px;
		font-size: 1.6rem;
		padding: 7px 15px 6px;
	}
	.field-element .field-input select {
		padding-right: 50px;
	}
}


/* ---- Checkboxes and radio buttons ---- */
.field-element.field-element--checkbox,
.field-element.field-element--radio {
	font-size: 1.6rem;
}

.field-element input[type="checkbox"],
.field-element input[type="radio"] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label {
	padding-left: 34px;
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before {
	content: " ";
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 13px;
	position: relative;
	top: 4px;
	background-color: $color-grey-03;
	border: none;
	margin-left: -34px;
	border-radius: 1px;
}

/* Check and dot */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after {
	content: " ";
	position: absolute;
	left: 1px;
	top: 6px;
	font-size: 15px;
}


/* Without labels */
.field-element.field-element--checkbox--no-label input[type="checkbox"] + label,
.field-element.field-element--radio--no-label input[type="radio"] + label {
	padding-left: 0;
}

.field-element.field-element--checkbox--no-label input[type="checkbox"] + label:before,
.field-element.field-element--radio--no-label input[type="radio"] + label:before {
	margin-left: 0;
	margin-right: 0;
}

.field-element.field-element--checkbox--no-label input[type="checkbox"]:checked + label:after,
.field-element.field-element--radio--no-label input[type="radio"]:checked + label:after {
	left: 1px;
}

.field-element.field-element.field-element--radio--no-label input[type="radio"]:checked + label:after {
	margin-left: 4px;
}


/* -- Checkboxes -- */

/* Check (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after {
	content: " ";
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -711px left 1px;
	width: 20px;
	height: 20px;
	margin: -2px 0 0 -1px;
}


/* BP 1 */
@media screen and (min-width: 600px) {
	.field-element--checkboxlist--columns .field-element__input-set {
		-moz-columns: 3;
		columns: 3;
	}
	.field-element--checkboxlist--columns--4 .field-element__input-set {
		-moz-columns: 4;
		columns: 4;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {

}

/* BP 4 */
@media screen and (min-width: 1300px) {

}


/* -- Radio Buttons -- */

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before {
	border-radius: 10px;
}

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after {
	left: 6px;
	top: 10px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: $color-secondary;
}

/* -- Varations -- */

/* White */
.field-element.field-element--white input[type="checkbox"] + label:before,
.field-element.field-element--white input[type="radio"] + label:before {
	background-color: #FFF;
}

/* Large */
.field-element.field-element--large input[type="checkbox"] + label:before,
.field-element.field-element--large input[type="radio"] + label:before {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	top: 5px;
}

.field-element.field-element--large input[type="radio"]:checked + label:after {
	left: 7px;
	top: 12px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
}
.field-element.field-element--large input[type="checkbox"]:checked + label:after {
	background-position: top -760px left 1px;
	width: 23px;
	height: 23px;
	margin: -2px 0 0 -1px;
}

/* ---- Color picker ---- */
.field-element--colorpicker .textbox.colorpicker {
	padding: 3px 8px;
	height: 43px;
	width: 50px;
}

/* Small */
.field-element--colorpicker.field-element--small .textbox.colorpicker {
	padding: 2px 7px;
	height: 33px;
	width: 39px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element.field-element--colorpicker .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element--large .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element-regular .textbox.colorpicker {
		padding: 2px 7px;
		height: 33px;
		width: 39px;
	}
}

/* ---- Money ---- */
.field-element--money .field-input .textbox {
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -447px left -10px;
}

.field-element--money .field-input {
	position: relative;
}
.field-element--money .field-input .money-symbol:before {
	content: "\00a4";		/* Generic currency sign */
	position: absolute;
	display: block;
	top: 9px;
	left: 0;
	color: $color-grey-03;
	font-weight: bold;
	font-size: 19px;
	width: 43px;
	text-align: center;
}
.field-element--money .field-input .money-symbol--dollar:before {
	content: "$";    /* Dollars */
}
.field-element--money .field-input .money-symbol--pound:before {
	content: "\00a3";    /* Pounds */
}
.field-element--money .field-input .money-symbol--yen:before {
	content: "\00a5";    /* Yen */
}
.field-element--money .field-input .money-symbol--indian_rupee:before {
	content: "\20B9";   /* Indian rupee */
}

/* White */
.field-element--money.field-element--white .field-input .textbox,
.field-element--money.field-element--white.field-element--small .field-input .textbox,
.field-element--money.field-element--white.field-element--large .field-input .textbox {
	background-position: top -607px left -4px;
}
.field-element--money.field-element--white .field-input .money-symbol:before {
	color: $color-secondary;
}

/* Small */
.field-element--money.field-element--small .field-input .textbox {
	background-position: top -447px left -10px;
}
.field-element--money.field-element--small .field-input .money-symbol:before {
	top: 2px;
}

/* Large */
.field-element--money.field-element--large .field-input .textbox {
	background-position: top -447px left -4px;
}
.field-element--money.field-element--large .field-input .money-symbol:before {
	top: 10px;
	width: 50px;
	font-size: 20px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element.field-element--money .field-input .textbox,
	.field-element.field-element--money.field-element--large .field-input .textbox,
	.field-element.field-element--money.field-element--regular .field-input .textbox {
		background-position: top -447px left 0;
		padding-left: 55px;
	}
	.field-element.field-element--white.field-element--money .field-input .textbox,
	.field-element.field-element--white.field-element--money.field-element--large .field-input .textbox,
	.field-element.field-element--white.field-element--money.field-element--regular .field-input .textbox {
		background-position: top -607px left 0;
	}
	.field-element--money .field-input .money-symbol:before,
	.field-element--money.field-element--large .field-input .money-symbol:before,
	.field-element--money.field-element--regular .field-input .money-symbol:before {
		top: 5px;
	}
}


/* ---- Time pickers ---- */

.field-element--timepicker .field-input .textbox {
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -168px left 13px, top -447px left -10px;
}

.ui-timepicker {
	display: block;
	position: absolute;
	height: 200px;
	overflow: auto;
	z-index: 9999 !important;
}

.ui-timepicker-standard {
	background-color: #FFF;
	border: 1px solid #CED2DC;
	display: block;
	margin: 3px 0 0;
	padding: 5px;
	list-style: none outside none;
	border-radius: 4px;
}

.ui-timepicker-standard a {
	color: #24292f;
}

.ui-timepicker-standard .ui-state-hover {
	background-color: #EAECF1;
}

.ui-timepicker-standard .ui-menu-item {
	clear: left;
	float: left;
	margin: 0;
	padding: 0;
	width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
	display: block;
	padding: 5px 10px;
	line-height: 1.5;
	text-decoration: none;
	border-radius: 3px;
}

.ui-timepicker-hidden {
	display: none;
}

/* White */
.field-element--timepicker.field-element--white .field-input .textbox {
	background-position: top -1716px left 18px, top -607px left 0;
}

/* White + Small */
.field-element--timepicker.field-element--white.field-element--small .field-input .textbox {
	background-position: top -1724px left 13px, top -607px left -10px;
}

/* White + Large */
.field-element--timepicker.field-element--white.field-element--large .field-input .textbox {
	background-position: top -1714px left 18px, top -607px left 0;
}

/* Small */
.field-element--timepicker.field-element--small .field-input .textbox {
	background-position: top -175px left 13px, top -447px left -10px;
}

/* Large */
.field-element--timepicker.field-element--large .field-input .textbox {
	background-position: top -166px left 16px, top -447px left -4px;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element.field-element--timepicker .field-input .textbox,
	.field-element.field-element--timepicker.field-element--large .field-input .textbox,
	.field-element.field-element--timepicker.field-element--regular .field-input .textbox {
		background-position: top -175px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element.field-element--white.field-element--timepicker .field-input .textbox,
	.field-element.field-element--white.field-element--timepicker.field-element--large .field-input .textbox,
	.field-element.field-element--white.field-element--timepicker.field-element--regular .field-input .textbox {
		background-position: top -175px left 12px, top -607px left 0;
	}
}

/* ---- Date pickers ---- */

/* Date picker and Date-range picker*/
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox {
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top 13px left 13px, top -447px left -10px;
}

/* White */
.field-element--datepicker.field-element--white .field-input .textbox,
.field-element--daterangepicker.field-element--white .field-input .textbox {
	background-position: top -1535px left 19px, top -607px left 0;
}
/* White + Small */
.field-element--datepicker.field-element--white.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--small .field-input .textbox {
	background-position: top -1543px left 14px, top -607px left -10px;
}
/* White + Large */
.field-element--datepicker.field-element--white.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--large .field-input .textbox {
	background-position: top -1532px left 19px, top -607px left 0;
}


/* Small */
.field-element--datepicker.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--small .field-input .textbox {
	background-position: top 6px left 14px, top -447px left -10px;
}

/* Large */
.field-element--datepicker.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--large .field-input .textbox {
	background-position: top 16px left 17px, top -447px left -4px;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element.field-element--datepicker .field-input .textbox,
	.field-element.field-element--datepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element.field-element--daterangepicker .field-input .textbox,
	.field-element.field-element--daterangepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--daterangepicker.field-element--large .field-input .textbox {
		background-position: top 6px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element.field-element--white.field-element--datepicker .field-input .textbox,
	.field-element.field-element--white.field-element--datepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--white.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element.field-element--white.field-element--daterangepicker .field-input .textbox,
	.field-element.field-element--white.field-element--daterangepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--white.field-element--daterangepicker.field-element--large .field-input .textbox {
		background-position: top 7px left 12px, top -607px left 0;
	}
}


/* Date/time picker */
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox {
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -87px left 14px, top -447px left -10px;
}

/* White */
.field-element--datetimepicker.field-element--white .field-input .textbox,
.field-element--datetimerangepicker.field-element--white .field-input .textbox {
	background-position: top -1635px left 19px, top -607px left 0;
}

/* White + Small */
.field-element--datetimepicker.field-element--small.field-element--white .field-input .textbox,
.field-element--datetimerangepicker.field-element--small.field-element--white .field-input .textbox {
	background-position: top -1643px left 12px, top -607px left -10px;
}
/* White + Large */
.field-element--datetimepicker.field-element--large.field-element--white .field-input .textbox,
.field-element--datetimerangepicker.field-element--large.field-element--white .field-input .textbox {
	background-position: top -1632px left 18px, top -607px left 0;
}

/* Small */
.field-element--datetimepicker.field-element--small .field-input .textbox,
.field-element--datetimerangepicker.field-element--small .field-input .textbox {
	background-position: top -94px left 13px, top -447px left -10px;
}

/* Large */
.field-element--datetimepicker.field-element--large .field-input .textbox,
.field-element--datetimerangepicker.field-element--large .field-input .textbox {
	background-position: top -85px left 17px, top -447px left -4px;
}



/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element.field-element--datetimepicker .field-input .textbox,
	.field-element.field-element--datetimepicker.field-element--large .field-input .textbox,
	.field-element.field-element--datetimepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--datetimerangepicker .field-input .textbox,
	.field-element.field-element--datetimerangepicker.field-element--large .field-input .textbox,
	.field-element.field-element--datetimerangepicker.field-element--regular .field-input .textbox {
		background-position: top -93px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element.field-element--white.field-element--datetimepicker .field-input .textbox,
	.field-element.field-element--white.field-element--datetimepicker.field-element--large .field-input .textbox,
	.field-element.field-element--white.field-element--datetimepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--white.field-element--datetimerangepicker .field-input .textbox,
	.field-element.field-element--white.field-element--datetimerangepicker.field-element--large .field-input .textbox,
	.field-element.field-element--white.field-element--datetimerangepicker.field-element--regular .field-input .textbox {
		background-position: top -93px left 12px, top -607px left 0;
	}
}


/* Container Appearance */
.daterangepicker {
	position: absolute;
	background: #fff;
	top: 100px;
	left: 20px;
	padding: 4px;
	margin-top: 2px;
	border-radius: 4px;
	width: 278px;
}

.daterangepicker.opensleft:before {
	position: absolute;
	top: -7px;
	right: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensleft:after {
	position: absolute;
	top: -6px;
	right: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.openscenter:before {
	position: absolute;
	top: -7px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.openscenter:after {
	position: absolute;
	top: -6px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.opensright:before {
	position: absolute;
	top: -7px;
	left: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensright:after {
	position: absolute;
	top: -6px;
	left: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.dropup{
	margin-top: -5px;
}

.daterangepicker.dropup:before{
	top: initial;
	bottom:-7px;
	border-bottom: initial;
	border-top: 7px solid #CED2DC;
}

.daterangepicker.dropup:after{
	top: initial;
	bottom:-6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
}

.daterangepicker.dropdown-menu {
	max-width: none;
	z-index: 3000;
	display: none;
	border: 1px solid #CED2DC;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
	float: none;
}

.daterangepicker .calendar {
	display: none;
	max-width: 270px;
	margin: 4px;
}

.daterangepicker.show-calendar .calendar {
	display: block;
}

.daterangepicker .calendar.single .calendar-table {
	border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
	white-space: nowrap;
	text-align: center;
	min-width: 32px;
	border: 1px solid #E8E8E8;
}

.daterangepicker .calendar-table {
	border: 1px solid #ddd;
	padding: 4px;
	border-radius: 4px;
	background: #fff;
}

.daterangepicker table {
	width: 100%;
	margin: 0;
	border: none;
}

.daterangepicker td,
.daterangepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	white-space: nowrap;
	padding: 2px;
}
.daterangepicker td,
.daterangepicker th.available {
	cursor: pointer;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	color: #999;
	background: #fff;
	border-color: #E8E8E8;
}
.daterangepicker td.off.in-range {
	background-color: #F4FAFC;
}
.daterangepicker td.off.active {
	background-color: #AAD8EA;
	color: #FFF;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background: #EAECF1;
}

.daterangepicker td.in-range {
	background: #ebf4f8;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #358AAB;
	border-color: #358AAB;
	color: #fff;
}

.daterangepicker td.week, .daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	margin-bottom: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 62px;
	background-color: #FFF;
	outline: none;
	border-radius: 5px;
	font-size: 1.4rem;
	line-height: 1.2;
	padding: 5px 23px 5px 8px;
	border: 1px solid #CED2DC;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -355px right -119px, top -607px right -115px;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 40%;
}

.daterangepicker select.yearselect {
	width: 44%;
}

.daterangepicker th.month {
	width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
	width: 11px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	display: block;
	margin: 0 auto;
}
.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
	background: none;
	opacity: 0.8;
}
.daterangepicker th.prev .daterangepicker-prev-icon {
	background-position: top -820px left 0px;
}
.daterangepicker th.next .daterangepicker-next-icon {
	background-position: top -880px left 1px;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
	border: none;
	background: none;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
	background-color: #F2F2F5;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #555;
	display: block;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	margin: 0 0 5px 0;
	padding: 0 6px 0 28px;
	width: 100%;
}

.daterangepicker .input-mini.active {
	border: 1px solid #358AAB;
	background-color: rgb(255, 255, 238);
}

.daterangepicker .daterangepicker_input i {
	position: absolute;
	left: 8px;
	top: 7px;
	color: #596474;
}

.daterangepicker .daterangepicker_input {
	position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
	text-align: center;
	margin: 5px auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
	font-size: 11px;
	float: none;
	margin: 4px;
	text-align: left;
}

.daterangepicker .applyBtn {
	background-color: #35ab75;
	color: #FFF;
}
.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
	background-color: #2C9867;
	color: #FFF;
}

.daterangepicker .cancelBtn {
	background-color: #D0D3DA;
	color: #24292F;
}
.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
	background-color: #BCBFC5;
	color: #24292f;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto 12px;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 1.3rem;
	background: #EAECF1;
	border: 1px solid #f5f5f5;
	color: #24292F;
	padding: 4px 12px 3px;
	margin-bottom: 7px;
	border-radius: 5px;
	cursor: pointer;
	line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
	background: #358AAB;
	border: 1px solid #358AAB;
	color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 600px) {
	.daterangepicker{
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 160px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker .calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker.single .calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .calendar{
		float:left;
	}

	.daterangepicker .calendar.right {
		margin-left: 0;
	}

	.daterangepicker .left .daterangepicker_input {
		padding-right: 12px;
	}

	.daterangepicker .calendar.left .calendar-table {
		padding-right: 12px;
	}

	.daterangepicker .ranges,
	.daterangepicker .calendar {
		float: left;
	}

}

@media (min-width: 769px) {
	.daterangepicker .ranges {
		width: auto;
		float: left;
	}

	.daterangepicker .calendar.left {
		clear: none;
	}
}


/* ---- File upload area ---- */
.file-upload__input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.file-upload__helptext {
	padding-top: 14px;
}
.file-upload__helptext--hidden {
	display: none;
}
.file-upload__helptext__line2 {
	display: block;
	font-size: 1.3rem;
}
.file-upload__helptext p {
	margin: 0;
}
.file-upload__helptext:before {
	content: " ";
	display: block;
	width: 27px;
	height: 32px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0 -1317px;
	margin: 0 auto 10px;
}

.file-upload__area {
	cursor: pointer;
	text-align: center;
}
.field-input .textbox.file-upload__area--dragenter {
	border-color: #35ab75;
}

.file-upload__uploads:before,
.file-upload__uploads:after {
	content: " ";
	display: table;
}

.file-upload__uploads:after {
	clear: both;
}
.file-upload__item,
.file-upload__helptext {
	height: 110px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.file-upload__item {
	background-color: #FFF;
	border: 1px solid #ced2dc;
	width: 110px;
	display: inline-block;
	margin-left: 6px;
	margin-right: 6px;
	position: relative;
	cursor: default;
	vertical-align: top;
}

.file-upload__item__remove {
	-webkit-appearance: none;
	padding: 0;
	outline: none;
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, 0.3);
}
.file-upload__item__remove:hover,
.file-upload__item__remove:focus {
	background: rgba(0, 0, 0, 0.7);
}

.file-upload__item__remove:after {
	content: " ";
	display: block;
	width: 17px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 3px -938px;
}
.file-upload__item__remove__text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Feedback */
.file-upload__item__feedback__response {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.file-upload__item__feedback__response p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
}

/* Not image response */
.file-upload__item__feedback__response .file-upload__item__feedback__name {
	font-size: 1.2rem;
	margin: 0 0 5px;
}
.file-upload__item__feedback__size {
	font-size: 1.1rem;
	margin: 0;
}
.file-upload__item__feedback__response--success--not-image {
	padding: 26px 15px 20px;
}
.file-upload__item__feedback__response--success--not-image:before {
	content: " ";
	display: block;
	width: 28px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1396px;
	margin: 0 auto 10px;
}

/* Image response */
.file-upload__item__feedback__image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

/* Error */
.file-upload__item__feedback__response--error {
	padding: 25px 15px 20px;
}
.file-upload__item__feedback__response .file-upload__item__feedback__error__text {
	font-size: 1.2rem;
	white-space: normal;
	overflow: visible;
}
.file-upload__item__feedback__response--error:before {
	content: " ";
	display: block;
	width: 23px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1473px;
	margin: 0 auto 10px;
}

/* Hover */
.file-upload__item__feedback__hover {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	color: #FFF;
	padding: 33px 15px 20px;
	overflow: hidden;
}
.file-upload__item:hover .file-upload__item__feedback__hover {
	opacity: 1;
}
.file-upload__item__feedback__hover__size {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover__dimensions {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover .file-upload__item__feedback__hover__name {
	font-size: 1.1rem;
	margin-bottom: 6px;
}

/* Progress circles */
.file-upload__progress-circle {
	position: relative;
	font-size: 1.3rem;
	line-height: 1.1;
	display: block;
	width: 56px;
	height: 56px;
	padding: 21px 0 0 0;
	margin: 26px auto 0;
	text-align: center;
}
.file-upload__progress-circle__amount {
	z-index: 1;
	position: relative;
}
.file-upload__progress-circle__pie {
	transform: rotate(-90deg);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
}
.file-upload__progress-circle__pie__piece {
	transition: all 100ms ease-in-out;
}



/* ---- File selector ---- */
.field-element--fileselector .fs-select-button {
	float: left;
	margin-right: 9px;
}

.field-element--fileselector .fs-preview-wrapper {
	background-color: #EEF0F3;
	position: relative;
	overflow: hidden;
	display: block;
}

.field-element--fileselector .fs-filename {
	padding: 10px 15px;
	word-break: break-all;
	overflow: hidden;
	display: block;
}
.field-element--fileselector .fs-file-selected .fs-filename {
	padding-left: 10px;
	padding-right: 38px;
}

.field-element--fileselector .fs-preview {
	width: 39px;
	display: none;
	float: left;
}
.field-element--fileselector .fs-file-selected .fs-preview {
	display: block;
}

.field-element--fileselector .fs-remove {
	display: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	background: none;
	padding: 0;
	color: #D4D7DE;
	position: absolute;
	right: 6px;
	top: 9px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -1075px left 3px;
	width: 22px;
	height: 22px;
}
.field-element--fileselector .fs-remove:hover,
.field-element--fileselector .fs-remove:focus {background-position: top -1135px left 3px;}
.field-element--fileselector .fs-file-selected .fs-remove {
	display: block;
}


/* Small */
.field-element--fileselector.field-element--small .fs-preview {
	width: 30px;
}
.field-element--fileselector.field-element--small .fs-filename {
	padding-top: 8px;
	padding-bottom: 6px;
	font-size: 1.4rem;
}
.field-element--fileselector.field-element--small .fs-remove {
	top: 6px;
	background-position: top -964px left 3px;
	width: 20px;
	height: 20px;
}
.field-element--fileselector.field-element--small .fs-remove:hover,
.field-element--fileselector.field-element--small .fs-remove:focus {
	background-position: top -1023px left 3px;
}
.field-element--fileselector.field-element--small .fs-file-selected .fs-filename {
	padding-right: 31px;
}

/* Large */
.field-element--fileselector.field-element--large .fs-preview {
	width: 48px;
}
.field-element--fileselector.field-element--large .fs-filename {
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 1.8rem;
}
.field-element--fileselector.field-element--large .fs-remove {
	top: 14px;
	right: 9px;
}
.field-element--fileselector.field-element--large .fs-file-selected .fs-filename {
	padding-right: 41px;
	padding-left: 20px;
}

/* Popup */
.file-selector-search-wrapper  {
	display: none;
}
.file-selector-search-wrapper.searched  {
	display: block;
}
#file-selector-preview {
	display: block;
	background: #f9f9fb;
	border: 1px solid #E0E3EA;
	margin: 0 0 20px;
	position: relative;
	padding: 20px 25px;
}
.no-results #file-selector-preview {
	display: none;
}
#file-selector-preview .preview-title {
	margin: 0 0 12px;
}
#file-selector-preview .preview-box {
	position: relative;
	height: 150px;
}
#file-selector-preview img {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
	display: block;
}

#file-selector-upload .field-element--new-category,
#file-selector-upload.new-category .field-element--select-category {
	display: none;
}
#file-selector-upload.new-category .field-element--new-category,
#file-selector-upload .field-element--select-category {
	display: block;
}

#file-selector-result-wrap {
	max-height: 450px;
	overflow: auto;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
}
#file-selector-upload .field-elements-inline,
#file-selector-search .field-elements-inline {
	display: -ms-flexbox;
	display: flex;
}
#file-selector-upload .field-elements-inline .field-element--dropdown,
#file-selector-search .field-elements-inline .field-element--dropdown {
	-ms-flex-positive: 1;
	    flex-grow: 1;
}
#file-selector-upload .field-elements-inline .field-element--text,
#file-selector-search .field-elements-inline .field-element--text {
	-ms-flex-positive: 1;
	    flex-grow: 1;
}

#file-selector-stats {
	font-size: 1.6rem;
	margin-bottom: 14px;
}
#file-selector-results .file-select-table {
	margin: 0;
	width: 100%;
	border-left: 1px solid #E0E3EA;
	border-right: 1px solid #E0E3EA;
}
#file-selector-results .file-select-table tr {
	border-bottom: 1px solid #D7D7D7;
	cursor: pointer;
}
#file-selector-results .file-select-table tr:last-child {
	border-bottom: 0;
}
#file-selector-results .file-select-table tr:nth-child(odd) {
	background-color: #f9f9fb;
}

/* Hover */
#file-selector-results .file-select-table tr:hover {
	background-color: #ECE9E9;
}

#file-selector-paginate {
	margin-top: 16px;
}
#file-selector-prev {
	float: left;
}
#file-selector-next {
	float: right;
}
#file-selector-results a {
	text-decoration: none;
	color: inherit;
	display: block;
}
#file-selector-results .file-select-table-thumbnail {
	width: 100px;
	padding: 5px 20px;
}
#file-selector-results .file-select-table-text a {
	padding: 27px 25px 27px 0;
}
#file-selector-results .table--content-standard tr:hover td {
	background-color: red;
}

/* ---- Range Selector / Jquery slider ---- */

.field-element--range h4 {
	font-family: $primary-font;
	font-size: 1.6rem;
	margin-bottom: -1.2em;
	margin-top: 4px;
    color: $color-grey-03;
    text-transform: uppercase;
	font-weight: bold;
	text-align: center;
}

.js-slider-min {
	margin-bottom: 0;
	display: inline-block;
}

.js-slider-max {
	margin-bottom: 0;
	float: right;
}

.ui-slider-horizontal {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 100%;
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
	margin-top: 10px;
	margin-bottom: 10px;
}

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-slider {
	position: relative;
	text-align: left;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 3.4rem;
	height: 3.4rem;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: .8em;
}
.ui-slider-horizontal .ui-slider-handle {
	margin-left: -2px;
}
.ui-slider-horizontal .ui-slider-handle:last-child {
	margin-left: -32px;
}
.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}
.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

/* Component containers
----------------------------------*/
.ui-widget {
	font-family: $primary-font;
	font-size: 1.1em;
}
.ui-widget .ui-widget {
	font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: $primary-font;
	font-size: 1em;
}
.ui-widget-content {
	background: #7B7D7B;
	color: #333333;
}
.ui-widget-content a {
	color: #333333;
}
.ui-widget-header {
	background: #6A823A;
	color: #ffffff;
	font-weight: bold;
}
.ui-widget-header a {
	color: #ffffff;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid $color-grey-05;
	background: $color-grey-03;
	font-weight: bold;
	top: -10px;
	color: #1c94c4;
	outline: none;
	border-radius: 1.7em;
	-webkit-border-radius: 1.7em;
	-moz-border-radius: 1.7em;
	-ms-border-radius: 1.7em;
	-o-border-radius: 1.7em;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
	color: #1c94c4;
	text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	outline: none;
	border-color: #C5C5C5;
	font-weight: bold;
	color: #c77405;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
	color: #c77405;
	text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	border: 2px solid #5897fb;
	font-weight: bold;
	color: #eb8f00;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
	color: #eb8f00;
	text-decoration: none;
}

/* ---- Autocomplete ---- */
.ui-autocomplete {
	list-style: none;
	background: #FFFFFF;
	max-height: 400px;
	overflow-y: auto;
	border: 1px solid #CED2DC;
	border-radius: 4px;
	padding: 3px 0;
	margin: 0;
}
.ui-autocomplete .ui-menu-item {
	padding: 5px 11px;
	cursor: pointer;
}
.ui-autocomplete .ui-menu-item.ui-state-focus {
	padding: 5px 11px;
	background: #358AAB;
	color: #FFF;
}


/* ---- Lightweight elements ---- */
.field-element--lightweight {
	margin: 0;
}


/* ---- TinyMCE tweaks ---- */
.mce-panel {
	border-color: #CED2DC !important;
}
.mce-toolbar-grp,
.mce-flow-layout {
	background-color: #EAECF1 !important;
}
.mce-tinymce{
	border-radius: 5px !important;
	overflow: hidden !important;
}
.mce-btn {
	border: 1px solid #EAECF1 !important;
	background-color: #EAECF1 !important;
}
.mce-btn:hover,
.mce-btn:focus{
	color: #333;
	background-color: #DDE0E6 !important;
	border-color: #C6CAD2 !important;
}
.mce-btn.mce-active,
.mce-btn.mce-active:hover{
	background-color: #CED2DC !important;
	border-color: #B5B8C1 !important;
}
.mce-btn:active{
	background-color:#e0e0e0 !important;
	border-color: #ccc !important;
}
.mce-menu-item:hover, .mce-menu-item.mce-selected, .mce-menu-item:focus {
	background-color: #358AAB !important;
	color: #FFF !important;
}


/* ---- Responsiveness ---- */

/* Large and regular field elements turn small */
@media screen and (max-width: 969px) {
	.field-element .field-input .textbox,
	.field-element.field-element--large .field-input .textbox,
	.field-element.field-element--regular .field-input .textbox {
		font-size: 1.6rem;
		padding: 7px 15px 6px;
	}

}


/* ---- Submit Bar ---- */
.submit-bar {
	text-align: right;
}
.submit-bar a {
	margin-right: 20px;
}


/* ---- Search Form ---- */
input.search-query {
	margin-bottom: 0;
	padding-right: 14px;
	padding-left: 14px;
	border-radius: 0;

}

.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
	border-radius: 0;
}

.form-search .input-append .search-query {
	border-radius: 14px 0 0 14px;
}

.form-search .input-append .btn {
	border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .search-query {
	border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .btn {
	border-radius: 14px 0 0 14px;
}

.form-search {
	/*radio*/
}
.form-search input,
.form-search textarea,
.form-search select {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
}
.form-search .hide {
	display: none;
}
.form-search label,
.form-search .btn-group {
	display: inline-block;
}
.form-search .radio,
.form-search .checkbox {
	margin-bottom: 0;
	padding-left: 0;
	vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"] {
	float: left;
}
.form-search .radio input[type="radio"] margin,
.form-search .checkbox input[type="checkbox"] margin {
	right: 3px;
	left: 0;
}
.form-search .control-group {
	margin-bottom: 0.75em;
}
.form-search legend + .control-group {
	margin-top: 1.5em;
	-webkit-margin-top-collapse: separate;
}


/* ---- Search pagination ---- */
.search-paginate {
	padding: 10px 0;
	text-align: center;
}
.search-paginate .page-prev {
	margin: 0 8px 0 0;
}
.search-paginate .page {
	padding: 2px 9px;
	margin: 0 3px;
	background: #eee;
	border: 1px #e85505 solid;
	text-decoration: none;
}
.search-paginate .page.on {
	color: #000;
	border-color: #000;
}
.search-paginate .page-next {
	margin: 0 0 0 8px;
}


/* ---- Captcha ---- */
.captcha {
	background-color: #f6f6f6;
	padding: 10px;
	width: 100%;
	border: 1px solid #D7D7D7;
}

.captcha .captcha-info, .captcha .info {
	float: right;
	padding-bottom: 8px;
}
.captcha .captcha-info, .captcha .info a {
	text-decoration: none;
}
.captcha .captcha-info .captcha-icon {
	width: 16px;
	height: 16px;
	margin-bottom: 16px;
}

.captcha .captcha-img {
	width: 200px;
	margin-bottom: 10px;
}

.captcha .captcha-input {
	width: 200px;
}


/* ------------------------------------------------------
**** Tables
------------------------------------------------------ */

table {
	border-collapse: collapse;
	border: 1px solid $color-grey-02;
}

th,
td {
	padding: 5px 10px;
	border: 1px solid $color-grey-02;
}

th,
thead td {
	background: $color-grey-01;
}

/* ---- Content tables ---- */
.table--content-standard {
	width: 100%;
	margin: 2rem 0;
}
.table--content-standard caption {
	text-align: left;
	padding-bottom: 13px;
}
.table--content-standard th,
.table--content-standard thead td {
	font-weight: bold;
	background-color: $color-grey-01;
	font-size: 1.7rem;
}
.table--content-standard td {
	background-color: #FFF;
}
.table--content-standard th,
.table--content-standard td {
	border: 1px solid $color-grey-02;
	padding: 12px 15px;
	text-align: left;
}


/* Small */
.table--content-standard.table--content-small {
	font-size: 1.3rem;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small td {
	padding: 5px 10px;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small thead td {
	font-size: 1.4rem;
}

/* ---- Responsive table ---- */
table.responsive {
	overflow: auto;
	overflow-y: hidden;
	overflow-x: auto;
	display: block;
}

/* ---- No Styles Table ---- */
.table__no-styles {
	border-collapse: separate !important;
	border: none !important;
	background: none !important;
	min-width: 0 !important;
}

.table__no-styles th,
.table__no-styles thead td,
.table__no-styles td {
	padding: 0 !important;
	border: 0 !important;
	background: none !important;
}




/* ------------------------------------------------------
**** Helper classes
------------------------------------------------------ */

/* ---- Clearfix ---- */
.clear {
	height: 0;
	clear: both;
	display: block;
}

.-clearfix {
	@include clearfix;
}


/* ---- Hiding classes ---- */
.-ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
}

.-ir:before {
	content: "";
	display: block;
	width: 0;
	height: 150%;
}

.-hidden {
	display: none !important;
	visibility: hidden;
}

.-vis-hidden {
	@include vis-hidden;
}



.-invisible {
	visibility: hidden;
}

/* ---- Pseudo elements ---- */

/* Angle Brackets */
.-r-arrow-after:after {
	content: "\00a0\003e";
}
.-r-arrow-before:before {
	content: "\003e\00a0";
}
.-l-arrow-after:after {
	content: "\00a0\003c";
}
.-l-arrow-before:before {
	content: "\003c\00a0";
}

/* Ellipsis */
.ellipsis-2:after {
	content: "..";
}
.ellipsis-3:after {
	content: "...";
}

/* ---- Floats and positioning ---- */

.left {
	margin: 0 30px 30px 0;
	float: left;
}

.right {
	margin: 0 0 30px 30px;
	float: right;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.block {
	margin: 0 0 30px;
	display: block;
}

.mobile-hide {
	display: none;
}

@media screen and (max-width: 600px) {
	.left,
	.center,
	.right {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
		display: block;
		float: none;
	}
}

@media screen and (min-width: $bp769) {
	.mobile-hide {
		display: inherit;
	}

	.desktop-hide {
		display: none;
	}
}

/* --- Text align ---- */
.text-align--right {
	text-align: right;
}
.text-align--center {
	text-align: center;
}


/* ------------------------------------------------------
**** Template Layout
------------------------------------------------------ */

* {
	margin: 0;
}

html,
body {
	background: #353c3a;
	height: 100%;
}

#wrap {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	min-height: 100%;
}

.container {
	@include container;

	body.full-width &.main-content {
		width: 100%;
	}

	/* Offset */
	&.container--offset-right {
		position: relative;
		width: 100%;
	}


	/* BP 2 */
	@include screen($bp769) {

		/* Offset */
		&.container--offset-right {
			margin-left: calc((100% - 700px) / 2);
			width: calc(100% - ((100% - 700px) / 2));
		}
	}


	@include screen($bp992) {

		/* Offset */
		&.container--offset-right {
			margin-left: calc(8% / 2);
			width: calc(100% - (8% / 2));
		}
	}

	@include screen($bp1600) {
		/* Offset */
		&.container--offset-right {
			max-width: unset;
			margin-left: calc((108% - 1600px) / 2);
			width: calc(100% - ((108% - 1600px) / 2));
		}
	}
}



/* ---- Popup container ---- */
/* container within magnific popup cannot handle the set widths */
/* BP 1 */
@media screen and (min-width: 600px) {
	.mfp-content .container {
		width: 100%;
		width: calc(100% - 100px);
	}
}

/* ---- Left padded left ---- */
/* BP 3 */
@include screen($bp992) {
	.left-padded-text {
		padding-left: 40px;
	}
}

/* ---- Side padded text ---- */


/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {
	.side-padded-text {
		padding-left: 60px;
		padding-right: 60px;
		margin-top: 20px;
		margin-bottom: 34px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.side-padded-text {
		padding-left: 100px;
		padding-right: 100px;
		margin-top: 40px;
		margin-bottom: 60px;
	}
}


/* ---- Text large ---- */

/* BP 1 */
@media screen and (min-width: 600px) {
	.text-large {
		font-size: 1.9rem;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {

}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.text-large {
		font-size: 2.1rem;
	}
}


/* ---- Subtitles ---- */
.subtitle {
	font-size: 1.3rem;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0 0 7px;
	font-family: $primary-font;
	position: relative;
	color: $color-grey-03;
}
.subtitle--dark-grey {
	color: $color-grey-08;
}
.subtitle.subtitle-lg {
	font-size: 1.6rem;
}
.subtitle a {
	text-decoration: none;
	color: $color-secondary;
}
.subtitle a:hover,
.subtitle a:focus {
	color: #000;
}

/* Orange */
.bg-orange .subtitle {
	color: $color-accent-01;
}
.bg-orange .subtitle.subtitle--lined:before {
	background-color: $color-accent-01;
}

/* Black */
.bg-black .subtitle {
	color: #FFF;
}
.bg-black .subtitle.subtitle--lined:before {
	background-color: #FFF;
}

/* Blue */
.bg-blue .subtitle {
	color: #8dc3ff;
}
.bg-blue .subtitle.subtitle--lined:before {
	background-color: #8dc3ff;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.subtitle {
		font-size: 1.4rem;
	}
	.subtitle.subtitle-lg {
		font-size: 1.6rem;
	}
	/* Lined */
	.subtitle.subtitle--lined:before {
		content: " ";
		height: 2px;
		background-color: $color-grey-03;
		right: 100%;
		width: 500px;
		position: absolute;
		display: block;
		margin-right: -1px;
		top: 8px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.subtitle {
		font-size: 1.6rem;
	}
	.subtitle.subtitle-lg {
		font-size: 1.8rem;
	}
	/* Lined */
	.subtitle.subtitle--lined:before {
		top: 9px;
	}
}


/* ---- Sections ---- */
.section {
	padding: 25px 0;

	@media screen and (min-width: 769px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@include screen($bp992) {
		padding-top: 75px;
		padding-bottom: 75px;
	}

	@media screen and (min-width: 1300px) {
		padding-top: 90px;
		padding-bottom: 90px;
	}

	&--no-padding-top {
		padding-top: 1px;
	}

	&--b0 {
		padding-bottom: 1px;
	}
}

body.full-width .section.section--content {
	padding-top: 25px;
}
body.tours-map .section.section--content {
	padding-top: 0;
}

.section-small {
	padding: $spacing*2 0;

	@include screen($bp560) {
		padding-top: $spacing*3;
		padding-bottom: $spacing*3;
	}
}



/* ---- Section titles ---- */
.section__title-wrap {
	margin: 0 0 20px;
}
.section__title-wrap__title {
	margin: 0;
	font-size: 2.4rem;
}
.section__title-wrap__float {
	margin-top: 15px;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.section__title-wrap__title {
		font-size: 2.9rem;
	}
	.section__title-wrap {
		margin-bottom: 30px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.section__title-wrap {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
	}
	.section__title-wrap__text {
		-ms-flex: 1 1 auto;
		    flex: 1 1 auto;
	}
	.section__title-wrap__float {
		-ms-flex: 0 0 auto;
		    flex: 0 0 auto;
		padding-left: 15px;
		margin-top: 0;
	}
}

/* BP 3 */
@include screen($bp992) {
	.section__title-wrap {
		margin-bottom: 40px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.section__title-wrap__title {
		font-size: 3.2rem;
	}
}

/* ---- Background colours ---- */

/* White */
.bg-white {
	background-color: #fff;
}

/* Black */
.bg-black {
	background-color: #000;
}
.bg-black,
.bg-black h2,
.bg-black .h2,
.bg-black h3,
.bg-black .h3 {
	color: #FFF;
}

/* Blue */
.bg-blue {
	background-color: $color-alternative;
}
.bg-blue,
.bg-blue h2,
.bg-blue .h2,
.bg-blue h3,
.bg-blue .h3,
.bg-blue a {
	color: #FFF;
}

/* Lightgrey */
.bg-lightgrey {
	background-color: $color-grey-01;
	color: $color-grey-05;
}

/* Grey */
.bg-grey {
	background-color: $color-grey-03;
}

/* Darkgrey */
.bg-darkgrey {
	background: $color-secondary;
}
.bg-darkgrey,
.bg-darkgrey a,
.bg-darkgrey h2,
.bg-darkgrey .h2,
.bg-darkgrey h3,
.bg-darkgrey .h3 {
	color: #FFF;
}
.bg-darkgrey a:hover {
	color: $color-grey-02;
}

/* Orange */
.bg-orange {
	background: $color-primary;
}
.bg-orange,
.bg-orange h2,
.bg-orange .h2 {
	color: #FFF;
}


/* ---- Subnav ---- */
.section--subnav {
	padding-top: 6px;
	padding-bottom: 6px;
	height: 48px;
	display: none;
}
.section--subnav:before,
.section--subnav:after {
	content: " ";
	display: table;
}

.section--subnav:after {
	clear: both;
}


/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {
	.section--subnav {
		display: block;
	}
}

/* BP 3 */
@include screen($bp992) {

}

/* BP 4 */
@media screen and (min-width: 1300px) {

}


/* ---- Header ---- */
.section--header {
	padding: 0;
	border-bottom: 1px solid $color-grey-02;
	height: 65px;
	display: none;
	z-index: 9999;
	position: relative;
}
.section--header:before,
.section--header:after {
	content: " ";
	display: table;
}

.section--header:after {
	clear: both;
}
.header__logo-wrap {
	display: block;
	margin: 4px 0;
	float: left;
}
.header__logo {
	width: 114px;
	height: 32px;
}

.section--header:before,
.section--header:after {
	content: " ";
	display: table;
}

.section--header:after {
	clear: both;
}

/* Fixed header */
.header-scrolled .section--header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}


/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {
	.section--header {
		display: block;
	}
	.header__logo-wrap {
		margin: 12px 0 0;
	}
	.header__logo {
		width: 150px;
		height: 42px;
	}
	.header-scrolled #wrap {
		margin-top: 65px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.section--header {
		height: 78px;
	}
	.header__logo-wrap {
		margin: 13px 0 0;
	}
	.header__logo {
		width: 190px;
		height: 53px;
	}
	.header-scrolled #wrap {
		margin-top: 78px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.section--header {
		height: 95px;
	}
	.header__logo-wrap {
		margin: 17px 0 15px;
	}
	.header-scrolled #wrap {
		margin-top: 95px;
	}
	.header__logo {
		width: 223px;
		height: 62px;
	}
}

#journey-map {
	margin: 0 0 2em;
	width: 100%;
	height: 400px;
}



/* ---- Tab navigation ---- */
.section--tab-navigation {
	padding-top: 16px;
	padding-bottom: 16px;
}
.tab-navigation__title {
	color: $color-accent-01;
	font-size: 1.6rem;
	float: left;
	margin: 0 0 9px;
}
.tab-navigation__list {
	display: none;
}
.tab-navigation__select .field-element {
	margin: 0;
}
.section--tab-navigation--wide .tab-navigation__list {
	width: 100%;
}

@import '01-components/slider/_home-banner';

@import '01-components/slider/_croc-slider';

// js tour video slider
.js-tour-video-slider {
	margin-top: 50px;
	padding: 0;
}

.js-tour-video-slider .slick-next {
	position: absolute;
	bottom: 0;
	z-index: 2;
	-webkit-appearance: none;
	border: none;
	outline: none;
	background: rgba(64,64,65,0.8);
	color: #FFF;
	padding: 0;
	width: 38px;
	height: 38px;
	text-indent: -9999px;
	cursor: pointer;
	width: 44px;
	height: 44px;
	top: 45%;
	right: 0;
}

.js-tour-video-slider .slick-next:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	text-indent: 0;
	position: absolute;
	top: 5px;
	left: 7px;
	font-size: 3.2rem;
	content: "\e901";
}

.js-tour-video-slider .slick-prev {
	position: absolute;
	bottom: 0;
	z-index: 2;
	-webkit-appearance: none;
	border: none;
	outline: none;
	background: rgba(64,64,65,0.8);
	color: #FFF;
	padding: 0;
	width: 38px;
	height: 38px;
	text-indent: -9999px;
	cursor: pointer;
	width: 44px;
	height: 44px;
	top: 45%;
	left: 0;
}

.js-tour-video-slider .slick-prev:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	text-indent: 0;
	position: absolute;
	top: 5px;
	left: 7px;
	font-size: 3.2rem;
	content: "\e903";
}





/* BP 1 */
@media screen and (min-width: 600px) {
	.tab-navigation__title {
		font-size: 1.7rem;
	}
	.tab-navigation__item__button {
		font-size: 1.2rem;
		padding: 11px 10px 11px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.section--tab-navigation {
		padding-top: 0;
		padding-bottom: 0;
	}
	.tab-navigation__select {
		display: none;
	}
	.tab-navigation__title {
		font-size: 2rem;
		margin: 17px 0 0;
		width: 300px;
		padding-right: 20px;
	}
	.tab-navigation__list {
		width: 400px;
	}

	.tab-navigation__list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 100%;
	}
	.tab-navigation__item {
		-ms-flex: 1 1 auto;
			flex: 1 1 auto;
	}
	.tab-navigation__item__button {
		color: #FFF;
		text-decoration: none;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 1.3rem;
		text-align: center;
		display: block;
		padding: 24px 10px 23px;
	}
	.tab-navigation__item__button:hover,
	.tab-navigation__item__button:focus,
	.tab-navigation__item__button:active {
		background-color: #cc5b0b;
		color: #FFF;
	}
	.tab-navigation__item--active .tab-navigation__item__button {
		background-color: #c05200;
	}
	.tab-navigation__title {
		margin: 20px 0;
	}

}

/* BP 3 */
@include screen($bp992) {
	.tab-navigation__title {
		width: 470px;
	}
	.tab-navigation__list {
		width: 430px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.section--tab-navigation .container {
		padding-left: 100px;
	}
	.tab-navigation__title {
		font-size: 2.1rem;
		width: 546px;
		margin: 29px 0 0;
	}
	.tab-navigation__list {
		width: 564px;
	}
	.tab-navigation__item__button {
		font-size: 1.6rem;
		padding: 34px 10px 33px;
	}
}

/* ---- find a tour ---- */
.section--find-a-tour {

}
@media screen and (min-width: 769px) {
	.section--refine-bar {
		padding-bottom: 25px;
	}
}

/* ---- Hover box list ---- */
.hover-box-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: -ms-flexbox;
	display: flex;
}
.hover-box-list__item {
	background-color: #000;
}
.hover-box-list__item__img {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 400ms ease;
	opacity: 0.75;
}
.hover-box-list__item__link {
	display: block;
	text-decoration: none;
}
.hover-box-list__item__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	color: #fff;
	text-shadow: 0 0 7px rgba(0,0,0,0.7);
}
.hover-box-list__item__text {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px;
	text-shadow: 0 0 7px rgba(0,0,0,0.7);
}
.hover-box-list__item__text .subtitle {
	color: #FFF;
	margin: 0;
}
.hover-box-list__item__footer {
	font-size: 1.9rem;
	line-height: 1.2;
	font-family: $accent-font;
	font-weight: 300;
	margin-bottom: 0;
}

.hover-box-list__item__footer__title {
	margin: 0;
}

.hover-box-list__item__footer__from {
	font-family: $primary-font;
	font-size: 1.2rem;
	text-transform: uppercase;
	margin: 0;
}

.hover-box-list__item__footer__from__price {
	font-size: 1.8rem;
	display: inline-block;
	font-weight: bold;
}


/* Hover */
.hover-box-list__item__link:hover .hover-box-list__item__img {
	transform: scale(1.1);
}
.hover-box-list__item__link:hover .hover-box-list__item__hover__inner {
	animation: fadeInShrink 500ms ease;
	animation-fill-mode: forwards;
}


/* TEMP */
.hover-box-list__item__img {
	background-image: url(../images/temp/hover-box.jpg);
}


/* BP 1 */
@media screen and (min-width: 350px) {
	.hover-box-list__item__footer {
		font-size: 2.3rem;
	letter-spacing: 0px;
	}
}
/* BP 1 */
@media screen and (min-width: 600px) {
	.hover-box-list__item__text,
	.hover-box-list__item__footer {
		padding: 25px;
	}
	.hover-box-list__item__footer {
		font-size: 2.6rem;
	}
	.hover-box-list__item__footer__from {
		font-size: 1.4rem;
		/* [02] */
	}
	.hover-box-list__item__footer__from__price {
		font-size: 2.2rem;
	}
}


/* BP 3 */
@include screen($bp992) {
	.hover-box-list__item__text,
	.hover-box-list__item__footer {
		padding: 30px 40px;
	}
	.hover-box-list__item__footer {
		font-size: 2.8rem;
	}
	.hover-box-list__item__footer__from {
		font-size: 1.6rem;
		/* [02] */
	}
}

/* Max iPad */
@media screen and (max-width: 1024px) {
	.hover-box-list__item__hover {
		display: none;
	}
}

/* iPad */
@media screen and (min-width: 1025px) {
	.hover-box-list__item__hover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		background-color: rgba(44,126,223,0.95);
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		    justify-content: center;
		-ms-flex-align: center;
		    align-items: center;
		opacity: 0;
		transition: all 300ms ease;
		padding-left: 40px;
		padding-right: 40px;
	}
	.hover-box-list__item__hover__inner {
		-ms-flex: 1 1 auto;
		    flex: 1 1 auto;
	}
	.hover-box-list__item__hover__inner h3 {
		margin-bottom: 0;
	}
	.hover-box-list__item__hover .price {
		color: #8dc3ff; /* subtitle colour */
	}
	.hover-box-list__item__hover .price span {
		text-transform: uppercase;
		font-size: 0.8em;
	}
	.hover-box-list__item__link:hover .hover-box-list__item__hover {
		opacity: 1;
	}
	.hover-box-list__item__link:hover .hover-box-list__item__text,
	.hover-box-list__item__link:hover .hover-box-list__item__footer {
		opacity: 0;
	}
}


/* ---- Feature box list ---- */
.feature-box-indent {
	margin: 0 15px;
}
.feature-box-list {
	list-style: none;
	padding: 0;
	margin-top: 20px;
	margin-bottom: 0;
}
.feature-box-list__item {
	margin: 0 0 30px;
}
.feature-box-list__item__link {
	text-decoration: none;
	color: inherit;
	display: block;
	height: 100%;
	position: relative;
}
.feature-box-list__item__inner {
	position: relative;
	padding: 20px 20px;
	transition: all 300ms ease;
	height: 100%;
}
.feature-box-list__item__inner:after {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 1px solid $color-grey-03;
	display: block;
	pointer-events: none;
}
.feature-box-list__item__image {
	margin: -19px -20px 15px;
	position: relative;
	overflow: hidden;
}
.feature-box-list__item__subtitle {
	margin: 0 0 4px;
}
.feature-box-list__item__text :last-child {
	margin-bottom: 0;
}
.feature-box-list__item__title {
	font-size: 1.8rem;
}
.feature-box-list__item__title:only-child {
	margin-bottom: 0;
}

.feature-box-list:last-child {
	margin-bottom: -25px;
}

.feature-box-list__item__image__img {
	transition: transform 500ms ease;
	width: 100%;
}

.feature-box-list__item__subtitle {
	font-size: 1.2rem;
}

.feature-box-list__item__image__img.-preload {
	filter: blur(2px);
}

/* Date */
.feature-box-list__item__date {
	position: absolute;
	top: 1px;
	left: 1px;
	z-index: 1;
	width: 46px;
	height: 46px;
	background: $color-alternative;
	color: #FFF;
	text-align: center;
	padding: 4px;
}
.feature-box-list__item__date__day {
	display: block;
	font-size: 2rem;
	line-height: 1.1;
}
.feature-box-list__item__date__month {
	display: block;
	text-transform: uppercase;
	line-height: 1.1;
	font-size: 1.1rem;
}

/* Hover */
.feature-box-list__item__link:hover,
.feature-box-list__item__link:active,
.feature-box-list__item__link:focus {
	color: inherit;
	outline: none;
}
.feature-box-list__item__link:hover .feature-box-list__item__inner {
	background: rgba(0, 0, 0, 0.03);
}
.feature-box-list__item__link:hover .feature-box-list__item__image__img {
	transform: scale(1.1);
}


.feature-box-list__item__hover {
	display: none;
}


/* BP 1 */
@media screen and (min-width: 600px) {
	.feature-box-list__item__date__day {
		font-size: 2.5rem;
	}
	.feature-box-list__item__date__month {
		font-size: 1.2rem;
	}
	.feature-box-list__item__date {
		width: 52px;
		height: 52px;
	}
	.feature-box-list__item__image {
		margin: -24px -20px 21px;
	}
	.feature-box-list__item__inner {
		padding: 25px 20px;
	}
	.feature-box-list__item__text {
		min-height: 54px;
	}
	.feature-box-list {
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.feature-box-list__item__title {
		font-size: 2rem;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.feature-box-list__item__title {
		font-size: 2.2rem;
	}
}

/* BP 3 */
@include screen($bp992) {
	.feature-box-list__item__inner {
		margin: 11px 13px 0;
	}
	.feature-box-list__item__image {
		margin: -36px -33px 21px;
	}
	.feature-box-list {
		margin-top: 40px;
	}
	.feature-box-list__item__date {
		width: 58px;
		height: 58px;
	}
	.feature-box-list__item__date__day {
		font-size: 2.8rem;
	}
	.feature-box-list__item__date__month {
		font-size: 1.3rem;
	}
	.feature-box-list__item__subtitle {
		font-size: 1.3rem;
	}
	.feature-box-list__item__hover {
	}
	/* Hover box */
	.feature-box-list__item__hover {
		display: block;
		position: absolute;
		top: -11px;
		left: 0;
		right: 0;
		bottom: -11px;
		text-align: center;
		opacity: 0;
		transition: all 300ms ease;
		padding: 50px 30px;
	}
	.feature-box-list__item__hover__button {
		margin: 20px 0 0;
	}
	.feature-box-list__item__link:hover .feature-box-list__item__hover {
		opacity: 1;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.feature-box-list:last-child {
		margin-bottom: -60px;
	}
	.feature-box-list__item__inner {
		margin: 15px 15px 0;
		padding: 18px 28px;
	}
	.feature-box-list__item__text {
		min-height: 85px;
	}
	.feature-box-list__item__image {
		margin: -44px -47px 20px;
	}
	.feature-box-list__item__title {
		font-size: 3.2rem;
	}
	.feature-box-list__item {
		margin-bottom: 60px;
	}
	.feature-box-list__item--small-bottom-margin {
		margin-bottom: 30px;
	}
	.feature-box-list__item__date__day {
		display: block;
		font-size: 3.2rem;
		line-height: 1.1;
	}
	.feature-box-list__item__date__month {
		font-size: 1.4rem;
	}
	.feature-box-list__item__date__day {
		font-size: 3.2rem;
	}
	.feature-box-list__item__date {
		width: 62px;
		height: 62px;
	}
	.feature-box-list__item__subtitle {
		font-size: 1.4rem;
	}
	.feature-box-list__item__hover {
		padding: 80px 30px;
		top: -16px;
		bottom: -16px;
	}
}
@media screen and (min-width: 1800px) {
	.col-xlg-4 .feature-box-list__item__title {
		font-size: 2.6rem;
	}
}


/* ---- Feature Box Companion Content ----  */

/* line up content with the border edges of feature boxes */

.feature-box-companion-content {
	margin-bottom: 20px;
}

/* BP 3 */
@include screen($bp992) {
	.feature-box-companion-content {
		padding: 0 13px 0;
		margin-bottom: 25px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.feature-box-companion-content {
		padding: 0 15px 0;
		margin-bottom: 30px;
	}
}


// Modern feature boxes to ideally replace old ones
// seperating layout from object

@import "02-layout/media-objects/feature-box-grid";

@import "02-layout/media-objects/feature-box";

@import "02-layout/media-objects/popup-box-slider";

@import "02-layout/media-objects/full-width-img-slider";

@import "02-layout/media-objects/blog-asym-grid";


/* ---- Popup boxes ---- */
.section--popup-boxes {
	padding: 0;
}
.popup-box-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.popup-box-list__item {
	text-align: center;
}
.popup-box-list__item__link {
	text-decoration: none;
	color: inherit;
	position: relative;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-wrap: nowrap;
}
.popup-box-list__item__link__inner {
	flex: 1 1 auto;
	height: 100%;
	padding: 30px 6%;
}
.popup-box-list__item__link:hover,
.popup-box-list__item__link:focus,
.popup-box-list__item__link:active {
	color: inherit;
}
.popup-box-list__item__img {
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.popup-box-list__item__img:before {
	content: " ";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.35);
	transition: all 300ms ease;
}
.popup-box-list__item__text {
	position: relative;
	z-index: 1;
	width: 100%;
}
.popup-box-list__item__title {
	font-size: 2.4rem;
	line-height: 1.12;
	margin-top: 8px;
	margin-bottom: 10px;
	color: #FFF;
}
.popup-box-list__item__text__content {
	margin: 0 0 26px;
}
.popup-box-list__item__text__content ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.popup-box-list__item__hover__price {
	text-transform: uppercase;
	font-size: 1.3rem;
}

.popup-box-list__item__hover__price__dollars {
	font-weight: bold;
	font-size: 2rem;
	line-height: 1;
}


/* TEMP */
.popup-box-list__item__img {
	background-image: url(../images/temp/popup-box-bg.jpg);
}




/* BP 1 */
@media screen and (min-width: 600px) {
	.popup-box-list__item__link .popup-box-list__item__link__inner {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.popup-box-list {
		height: 400px;
		display: flex;
		flex-wrap: nowrap;
	}
	.popup-box-list__item {
		flex: 1 1 auto;
		width: 33.3333333%;
		height: 100%;
	}
	.popup-box-list__item__link__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

/* BP 3 */
@include screen($bp992) {
	.popup-box-list__item__link {
		padding: 40px 35px;
	}
	.popup-box-list__item__title {
		font-size: 2.8rem;
		margin-bottom: 20px;
	}

}

/* iPad */
@media screen and (min-width: 1025px) {
	.popup-box-list {
		height: 400px;
	}
	.popup-box-list__item__hover {
		max-height: 0;
		overflow: hidden;
		margin: 0;
		opacity: 0;
		transition: all 200ms ease 100ms;
		animation: fadeOutDown 100ms ease;
		animation-fill-mode: forwards;
	}
	.popup-box-list__item__link .popup-box-list__item__link__inner {
		padding: 88px 35px 103px;
	}
	.popup-box-list__item__link:hover,
	.popup-box-list__item__link:focus,
	.popup-box-list__item__link:active {
		outline: none;
	}
	.popup-box-list__item,
	.popup-box-list__item__link {
		transition-delay: 150ms;
	}
	.popup-box-list__item:hover,
	.popup-box-list__item__link:hover {
		transition-delay: 0ms;
	}

	.popup-box-list__item__link:hover .popup-box-list__item__img:before {
		background-color: rgba(59,123,219,0.95);
	}
	.popup-box-list__item__link:hover .subtitle {
		color: #8dc3ff;
	}
	.popup-box-list__item__link:hover .button {
		border-color: #8ec3ff;
		color: #fff;
	}
	.popup-box-list__item__link:hover .button:hover,
	.popup-box-list__item__link:hover .button:focus {
		border-color: #8ec3ff;
		color: #fff;
	}

	.popup-box-list__item__subtitle {
		transition: all 400ms ease;
	}
	.popup-box-list__item__link:hover .popup-box-list__item__subtitle {
		opacity: 0;
	}

	.popup-box-list__item__link:hover .popup-box-list__item__hover {
		animation: fadeInUp 500ms ease 300ms;
		animation-fill-mode: forwards;
		max-height: 200px;
		transition-delay: 300ms;
	}
	.popup-box-list__item {
		transition: all 400ms ease;
	}
	.popup-box-list__item:hover {
		margin-top: -30px;
		transition: all 500ms ease;
		height: calc(100% + 30px);
	}

	.popup-box-list--downwards .popup-box-list__item:hover {
		margin-top: 0;
		margin-bottom: -30px;
		height: calc(100% + 30px);
	}

	.popup-box-list__item__link {
		transition: all 200ms ease;
	}
	.popup-box-list__item__link:hover .popup-box-list__item__link__inner {
		transition: all 300ms ease;
		padding-top: 30px;
		padding-bottom: 50px;
	}

}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.popup-box-list__item__title {
		font-size: 3.2rem;
		margin-bottom: 30px;
		margin-top: 20px;
	}
	.popup-box-list__item__link__inner {
		padding: 88px 40px 103px;
	}
	.popup-box-list__item__link:hover .popup-box-list__item__link__inner {
		padding-top: 15px;
		padding-bottom: 60px;
	}
}


/* ---- Itinerary Item list ---- */
.itinerary-list {
	list-style: none;
	padding: 0;
	margin: 30px 0;
}
.itinerary-item {
	border: 1px solid $color-grey-03;
	margin: 0 0 24px;
	background: #FFF;
}
.itinerary-item:last-child {
	margin-bottom: 0;
}
.itinerary-item__img-wrap {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.objectfit .itinerary-item__img-wrap {
	background-image: none !important;
}
.no-js .itinerary-item__img,
.no-objectfit .itinerary-item__img {
	display: none;
}
.itinerary-item__img {
	object-fit: cover;
	height: 180px;
	width: 100%;
}
.itinerary-item__content {
	padding: 20px 23px 5px;
}
.itinerary-item__subcontent {
	padding: 0 23px 20px;
}
.itinerary-item__subcontent ul:not(.icon-list) {
	list-style-type: initial;
}
.itinerary-item__img-wrap {
	position: relative;
}
.itinerary-item__img-wrap a {
	display: block;
	height: 100%;
}
.itinerary-item__img__credit {
	margin: 0;
	position: absolute;
	bottom: 20px;
	right: 20px;
	padding-left:  20px;
	text-transform: uppercase;
	font-size: 1.1rem;
	color: rgba(255,255,255,0.9);
	text-shadow: 0 0 7px #000;
	pointer-events: none;
}



/* BP 1 */
@media screen and (min-width: 600px) {
	.itinerary-item__img {
		height: 240px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.image-title-content__title {
		height: 100%;
		background-color: rgba(61,68,66,0.5);
		max-width: 311px;
		padding: 38px 36px;
	}
	.image-title-content__title,
	.image-title-content__title .subtitle,
	.image-title-content__title .h2 {
		color: #FFF;
	}
	.image-title-content__title-wrap {
		height: 330px;
	}
	.image-title-content__content .itinerary-list {
		margin-top: 0;
	}
}

/* BP 3 */
@include screen($bp992) {
	.itinerary-item {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		margin: 0 0 55px;
	}
	.itinerary-item__img-wrap {
		-ms-flex: 1 0 30%;
		flex: 1 0 30%;
		max-width: 310px;
		height: 380px;
	}
	.itinerary-item__content {
		-ms-flex: 1 1 40%;
		    flex: 1 1 40%;
	}
	.itinerary-item__subcontent {
		-ms-flex: 1 1 40%;
		    flex: 1 1 40%;
		max-width: 230px;
		border-left: 1px solid $color-grey-03;
	}
	.itinerary-item__img {
		height: 100%;
	}
	.itinerary-item__content,
	.itinerary-item__subcontent {
		padding: 20px 30px;
	}
	.itinerary-list {
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.image-title-content__title-wrap {
		height: 410px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.image-title-content__title {
		padding: 50px 53px;
	}
	.itinerary-item__content {
		-ms-flex: 1 1 50%;
		    flex: 1 1 50%;
	}
	.itinerary-item__subcontent {
		-ms-flex: 1 1 20%;
		    flex: 1 1 20%;
	}
	.itinerary-item__content,
	.itinerary-item__subcontent {
		padding: 25px 40px;
	}
	.image-title-content__title {
		width: 30%;
		max-width: 311px;
		padding: 38px 36px;
	}
	.image-title-content__title-wrap {
		height: 515px;
	}
}

/* ---- Outline box ---- */
.outline-box-list {
	list-style: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: -20px;
}

.outline-box-list:before,
.outline-box-list:after {
	content: " ";
	display: table;
}

.outline-box-list:after {
	clear: both;
}

.outline-box-list__item {
	margin-bottom: 20px;
}

.outline-box-list__item__inner {
	border: 1px solid $color-grey-03;
	padding: 30px 25px;
	height: 100%;
	text-align: center;
}

.outline-box-list__item__inner .icon-circle {
	margin: 0 auto 15px;
}

.outline-box-list__item__content p:last-child {
	margin-bottom: 0;
}

/* BP 1 */
@media screen and (min-width: 600px) {

	.outline-box-list__item {

	}
}

/* BP 2 */
@media screen and (min-width: 769px) {

	.outline-box-list {
		margin-bottom: 0;
		margin-left: -15px;
		margin-right: -15px;
	}

	.outline-box-list__item__inner .icon-circle {
		margin-bottom: 16px;
	}

	.outline-box-list__item {
		margin-bottom: 15px;
		float: left;
		width: 33.333333333%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.outline-box-list__item:nth-child(3n+1) {
		clear: left;
	}
}

/* BP 3 */
@include screen($bp992) {
	.outline-box-list__item__inner .icon-circle {
		margin-bottom: 23px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.outline-box-list__item__inner {
		padding: 40px 32px;
	}
}

@supports (display: grid) {
	.outline-box-list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-gap: 2vw;
		margin-left: 0vw;
		margin-right: 0vw;
	}

	.outline-box-list:before,
	.outline-box-list:after {
		display: none;
	}

	.outline-box-list__item {
		width: auto;
		padding: 0;
	}
}


/* ---- Icon Large ---- */
.icon-large {
	font-size: 120px;
	text-align: center;
	display: grid;

	@include screen($bp768) {
		font-size: 180px;
	}
}


/* ---- Icon circles ---- */
.icon-circle {
	background-color: $color-grey-03;
	display: block;
	border-radius: 50%;
	color: $color-secondary;
	text-align: center;
	position: relative;
}
.icon-circle:before {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
}


a.icon-circle:hover,
a.icon-circle:focus {
	background-color: #dedede;
	color: $color-secondary;
}

/* Darkgrey */
.bg-darkgrey a.icon-circle:hover {
	background-color: #ffffff;
}

/* Small */
.icon-circle:before,
.icon-circle.icon-circle--small:before {
	top: 5px;
}
.icon-circle,
.icon-circle.icon-circle--small {
	width: 42px;
	height: 42px;
	font-size: 3.3rem;
}

/* BP 3 */
@include screen($bp992) {
	.icon-circle {
		width: 74px;
		height: 74px;
		font-size: 6.2rem;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {

	.icon-circle:before {
		top: 7px;
	}

	/* Large */
	.icon-circle.icon-circle--large {
		width: 96px;
		height: 96px;
		font-size: 8.1rem;
	}

}


/* Bg darkgrey */
.bg-darkgrey .icon-circle {
	color: $color-secondary;
}
.bg-darkgrey .icon-circle:hover,
.bg-darkgrey .icon-circle:focus {
	color: $color-secondary;
}

/* Lists */
.icon-circle-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: -ms-flexbox;
	display: flex;
}
.icon-circle-list a {
	text-decoration: none;
}

/* Justifycenter */
.icon-circle-list.icon-circle-list--justifycenter {
	-ms-flex-pack: center;
	    justify-content: center;
}


/* Inline */
.icon-circle-list--inline .icon-circle-list__item {
	-ms-flex: 0 1 auto;
	    flex: 0 1 auto;
	margin-right: 10px;
}

/* Small */
.icon-circle-list--inline.icon-circle-list--small .icon-circle-list__item {
	margin-right: 7px;
}

.icon-circle-list--inline .icon-circle-list__item:last-child {
	margin-right: 0;
}



/* ---- Refine bar ---- */
.section--refine-bar {
	padding-top: 18px;
	padding-bottom: 15px;
	position: relative;
	z-index: 0;
}
.section--refine-bar:before,
.section--refine-bar:after {
	content: "";
	background-color: $color-secondary;
	width: 50%;
	width: 30vw;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 0;
}
.section--refine-bar:before {
	right: 100%;
	right: calc(100% - 1px);
}
.section--refine-bar:after {
	left: 100%;
	left: calc(100% - 1px);
}
.refine-bar__container {
	width: 100%;
}
.refine-bar__title {
	margin: 0 0 18px;
}

.home-refine-bar {
	display: grid;
	column-gap: $spacing*3;

	@include screen($bp600) {
		grid-template-columns: 1fr 1fr;
	}

	@include screen($bp992) {
		grid-template-columns: 1fr 1fr 1fr auto;
	}
}

/* Extra BP */
@media screen and (min-width: 400px) {
	.icon-circle-list--inline .icon-circle-list__item {
		margin-right: 14px;
	}
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.refine-bar__form .field-element {
		width: 100%;
	}
	.refine-bar__form .field-element--range {
		width: 100%;
	}
	.refine-bar__form {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		margin-right: -2%;
		margin-left: -2%;
	}
	.refine-bar__form .field-element {
		margin-right: 2%;
		margin-left: 2%;
	}
	.field-element--refine-bar__form__duration {
		-ms-flex: 1 1 10%;
			flex: 1 1 10%;
	}
	.field-element--refine-bar__form__month {
		-ms-flex: 1 1 10%;
			flex: 1 1 10%;
	}
	.refine-bar__form .field-element.field-element--button {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
			-ms-flex: 1 1 auto;
				flex: 1 1 auto;
		width: 100%;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.refine-bar__form {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.section--refine-bar {
		padding-top: 25px;
		padding-bottom: 29px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.refine-bar__form .field-element--button {
		margin-bottom: 0;
	}
}
@media screen and (min-width: 1280px) {
	.refine-bar__form .field-element--button .button {
		width: 16.666%;
		min-width: 112px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.section--refine-bar {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.section--refine-bar .container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	}
	.refine-bar__form-wrap {
		-ms-flex: 1 1 auto;
		    flex: 1 1 auto;
	}
	.refine-bar__title-wrap {
		width: 100%;
	}
	.icon-circle-list--inline .icon-circle-list__item {
		margin-right: 24px;
	}
	.refine-bar__form .field-element {
		width: 29.3333%;
	}
	.refine-bar__form .field-element--range {
		width: 46%;
	}
}

/* ---- basic slick docs ---- */

.slick-dots {
	@include list-reset;
	position: absolute;
	display: grid;
	grid-auto-flow: column;
	gap: $spacing*1.5;

	button {
		-webkit-appearance: none;
		padding: 0;
		outline: none;
		border: none;
		text-indent: -100vw;
		width: 14px;
		height: 14px;
		overflow: hidden;
		border-radius: 50%;
		transition: all 200ms ease;
		display: block;
		background-color: $color-grey-02;

		@include screen($bp992) {
			width: 16px;
			height: 16px;
		}


		&:hover,
		&:focus,
		&:active {
			background-color: darken($color-grey-02, 20%)
		}

	}
	.slick-active {
		button,
		button:hover,
		button:focus,
		button:active {
			background: $color-alternative;
		}
	}
}

/* ---- basic slick arrow ---- */
:root {
	--slick-arrow-width: 32px;
}
.slick-arrow {
	width: var(--slick-arrow-width);
	height: 80px;
	overflow: hidden;
	text-indent: 100vw;
	border: none;
	background: no-repeat center center;
	position: absolute;
	background-size: 80% auto;
	transition: background 200ms ease-in-out;

	&.slick-next {
		right: 0;
		background-position: left center;

		background-image: url(../images/icon-stroke_chevron-right.svg);

		@include screen($bp600) {
			&:hover,
			&:focus,
			&:active {
				background-position: right center;
			}
		}
	}

	&.slick-prev {
		left: 0;
		background-position: right center;
		background-image:  url(../images/icon-stroke_chevron-left.svg);

		@include screen($bp600) {
			&:hover,
			&:focus,
			&:active {
				background-position: left center;
			}
		}

	}
}

/* ---- Croc slider list ---- */
.croc-slider-list {
	list-style: none;
	padding: 0;
	margin: 0;
	position: relative;
	z-index: 1000;
	padding: 0;
}
.croc-slider-list__item {
	outline: none;
}
.section--croc-slider {
	position: relative;
}
.croc-slider-list__item__img {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 160px;
	margin: 0 0 20px;
}
.croc-slider-list__item__text {
	margin: 0 0 22px;
}


.croc-slider-image-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.croc-slider-image-list,
.slick-slider.croc-slider-image-list {
	display: none;
}


.croc-slider-image-list.slick-slider,
.croc-slider-image-list .slick-list,
.croc-slider-image-list .slick-track {
	position: static !important;
	transform: none !important;
}
.croc-slider-image-list .slick-slide {
	transform: none !important;
	position: absolute !important;
	left: 0 !important;
	width: calc((100% - 1210px) / 2 + 372px) !important;
	outline: none;
}


.croc-slider-list .slick-dots {
	bottom: 21px;
	right: 0;
}
.croc-slider-list .slick-dots button {
	background: $color-grey-03;
}
.croc-slider-list .slick-dots button:hover {
	background: #b1b1b1;
}
.croc-slider-list__item:nth-child(1n+2),
.croc-slider-image-list__item:nth-child(1n+2) {
	display: none;
}

/* TEMP */
.croc-slider-image-list__item,
.croc-slider-list__item__img {
	background-image: url(../images/temp/croc-slider-bg.jpg);
}
.croc-slider-image-list__item[data-slide-item="2"],
.croc-slider-list__item[data-slide-item="2"] .croc-slider-list__item__img {
	background-image: url(../images/temp/croc-slider-bg2.jpg);
}
.croc-slider-image-list__item[data-slide-item="3"],
.croc-slider-list__item[data-slide-item="3"] .croc-slider-list__item__img {
	background-image: url(../images/temp/croc-slider-bg3.jpg);
}
.croc-slider-image-list__item[data-slide-item="4"],
.croc-slider-list__item[data-slide-item="4"] .croc-slider-list__item__img {
	background-image: url(../images/temp/croc-slider-bg4.jpg);
}

/* Extra BP */
@media screen and (min-width: 400px) {
	.croc-slider-list__item__img {
		height: 200px;
	}
}


/* BP 1 */
@media screen and (min-width: 600px) {
	.croc-slider-image-list .slick-slide {
		width: calc((100% - 500px) / 2 + 372px) !important;
	}
	.croc-slider-list__item__img {
		height: 270px;
		margin-bottom: 27px;
	}
}


/* BP 2 */
@media screen and (min-width: 769px) {
	.croc-slider-image-list .slick-slide,
	.croc-slider-image-list__item {
		width: calc((100% - 700px) / 2 + 270px) !important;
	}
	.croc-slider-image-list,
	.slick-slider.croc-slider-image-list {
		display: block;
	}
	.croc-slider-list {
		padding: 0 0 0 320px;
	}
	.croc-slider-image-list__item {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}
	.croc-slider-list__item__img {
		display: none;
	}
}

/* BP 3 */
@include screen($bp992) {
	.croc-slider-image-list .slick-slide,
	.croc-slider-image-list__item {
		width: calc((100% - 900px) / 2 + 310px) !important;
	}
	.croc-slider-list {
		padding: 55px 8% 55px 41%;
		border: 1px solid $color-grey-03;
	}
	.croc-slider-list .slick-dots {
		bottom: 73px;
		right: 50px;
	}
	.croc-slider-list__item__text {
		margin-bottom: 30px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.croc-slider-image-list .slick-slide,
	.croc-slider-image-list__item {
		width: calc((100% - 1210px) / 2 + 372px) !important;
	}
	.croc-slider-list {
		padding: 72px 8% 72px 39%;
	}
	.croc-slider-list .slick-dots {
		bottom: 91px;
		left: 759px;
	}
}



/* ---- Pre footer ---- */
.pre-footer__enews-form {
	display: -ms-flexbox;
	display: flex;
	margin-top: 17px;
}
.pre-footer__enews-form .field-element {
	margin-bottom: 0;
	-ms-flex: 1 1 auto;
	    flex: 1 1 auto;
	margin-right: 9px;
}
.pre-footer__enews-form .field-element--button {
	-ms-flex: 0 1 auto;
	    flex: 0 1 auto;
}

/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {

}

/* BP 3 */
@include screen($bp992) {
	.pre-footer__enews-form {
		margin-top: 26px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.pre-footer__enews-form {
		margin-top: 32px;
	}
}


/* ---- Footer ---- */
.section--footer {
	padding-top: 34px;
	padding-bottom: 18px;
	position: relative;
	font-size: 1.2rem;
	text-align: center;
	position: relative;
}

.section--footer,
.section--footer a {
	color: $color-grey-05;
}
.section--footer a {
	text-decoration: none;
}
.section--footer a:hover {
	color: $color-secondary;
}

.footer-links-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.footer-links-list__item,
.footer-links-list__item a {
	display: inline-block;
}
.footer-links-list__item:after {
	content: " | ";
	display: inline-block;
}
.footer-links-list__item:last-child:after {
	content: none;
}


.pre-footer__social {
	margin-bottom: 20px;
}

.footer-copyright {
	margin: 0;
}

.nothing-like-australia-icon {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: -21px;
	width: 100px;
	background-color: $color-alternative;
	padding: 10px;
}

@media screen and (max-width: 767px) {
	.section--pre-footer {
		padding-bottom: 53px;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.footer-links-list {
		float: right;
	}
	.section--footer {
		text-align: left;
		padding-top: 38px;
	}
	.pre-footer__social {
		margin-bottom: 0;
	}
	.nothing-like-australia-icon {
		top: -26px;
		width: 130px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.section--footer {
		padding-top: 50px;
		padding-bottom: 26px;
	}
	.section--pre-footer {
		padding-bottom: 95px;
	}
	.nothing-like-australia-icon {
		background: none;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: -56px;
		width: 110px;
		height: 110px;
	}

.nothing-like-australia-icon__img {
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%)
}
	.nothing-like-australia-icon:before {
		content: " ";
		background-color: $color-alternative;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		transform: rotate(45deg);
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.section--footer {
		font-size: 1.6rem;
		padding-top: 56px;
		padding-bottom: 36px;
	}
	.section--pre-footer {
		padding-bottom: 120px;
	}
	.nothing-like-australia-icon {
		top: -71px;
		width: 142px;
		height: 142px;
	}
}

/* ---- Content ---- */


body.full-width .breadcrumb,
body.full-width h1 {
	display: none;
}
body.full-width .container.main-content .row.to-edge {
	margin-left: 0px;
	margin-right: 0px;
}

.row.to-edge .fieldset-input.button {
	width: 100%;
}
.row.to-edge .fieldset-input.button input {
	display: none;
}


/* BP 2 */
@media screen and (min-width: 769px) {
	.section--content--padded .container,
	.section--content--lined .container {
		padding-left: 50px;
	}
	body.full-width .section--content--padded .container,
	body.full-width .section--content--lined .container {
		padding-right: 50px;
	}
	body.full-width .container.main-content .row.to-edge {
		margin-left: -50px;
		margin-right: -30px;
	}
	.section--content--lined .container {
		position: relative;
		padding-bottom: 35px;
	}
	.section--content--lined .container:before,
	.section--content--lined .container:after {
		content: " ";
		position: absolute;
		left: 0;
		bottom: 0;
		background: $color-grey-02;
		display: block;
	}

	/* Lined */
	.section--content--lined {
		overflow: hidden;
	}

	.section--content--lined .container:before {
		top: -100%;
		width: 1px;
	}
	.section--content--lined .container:after {
		right: -100%;
		height: 1px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.section--content--padded .container,
	.section--content--lined .container {
		padding-left: 70px;
	}
	body.full-width .section--content--padded .container,
	body.full-width .section--content--lined .container {
		padding-right: 70px;
	}
	.section--content--lined .container {
		padding-bottom: 55px;
	}
	body.full-width .container.main-content .row.to-edge {
		margin-left: -60px;
		margin-right: -50px;
	}
	body.tours-map .section--content--lined .container {
		padding-right: 0;
		padding-left: 0;
	}
	body.tours-map .container.main-content .row.to-edge {
		margin-right: 0;
		margin-left: 0;
	}
	body.tours-map .container.main-content .row.to-edge .col-lg-4 {
		padding-left: 0;
	}
}

/* BP 4 */
@include screen($bp1300) {
	.section--content--padded .container,
	.section--content--lined .container {
		padding-left: 100px;
	}
	body.full-width .section--content--padded .container,
	body.full-width .section--content--lined .container {
		padding-right: 100px;
	}
	.section--content--lined .container {
		padding-bottom: 75px;
	}
	body.full-width .container.main-content .row.to-edge {
		margin-left: -100px;
		margin-right: -100px;
	}
	body.tours-map .section--content--lined .container {
		padding-right: 0;
		padding-left: 0;
		max-width: unset;
	}
	body.tours-map .container.main-content .row.to-edge {
		margin-right: 0;
		margin-left: 0;
	}
	body.tours-map .container.main-content .row.to-edge .col-lg-4 {
		padding-left: 0;
	}
}


/* ---- Outline content ---- */
.section--outline-content {
	position: relative;
	padding-top: 0;
}
.outline-content__img {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 130px;

	/* TEMP */
	background-image: url(../images/temp/outline-content-bg.jpg);
}
.outline-content__content__text {
	padding: 10px 0 0;
}
.outline-content__content__side__content {
	padding: 25px 0 0;
}

.outline-content__pull-img,
.outline-content__content__side .widget-Sprout-Widgets-MapWidget {
	display: none;
}
.outline-content__pull-img img,
.outline-content__content__side .widget-Sprout-Widgets-MapWidget img {
	max-width: 170px;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.outline-content__content__text {
		padding: 10px 0 0;
	}
	.outline-content__img {
		height: 200px;
	}
	.outline-content__pull-img,
	.outline-content__content__side .widget-Sprout-Widgets-MapWidget {
		display: block;
		margin: -80px auto 20px;
		text-align: center;
	}
	.outline-content__content__side__content {
		padding: 0;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.outline-content__img {
		height: 300px;
	}
	.outline-content__content {
		margin-top: -60px;
	}
	.outline-content__content__text {
		padding: 99px 50px 27px 0;
	}
	.outline-content__pull-img,
	.outline-content__content__side .widget-Sprout-Widgets-MapWidget {
		margin: -70px 0 35px -1px;
	}
	.outline-content__pull-img img,
	.outline-content__content__side .widget-Sprout-Widgets-MapWidget img {
		max-width: 100%;
	}
	.outline-content__content {
		border: 1px solid $color-grey-03;
	}
	.outline-content__content__side__content {
		padding: 0 0 25px 35px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.outline-content__img {
		height: 360px;
	}
	.outline-content__pull-img,
	.outline-content__content__side .widget-Sprout-Widgets-MapWidget {
		margin: -100px 0 35px -1px;
		display: block;
	}
	.outline-content__content__text {
		padding: 134px 50px 43px;
	}
	.outline-content__content {
		margin-top: -80px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.outline-content__img {
		height: 480px;
	}
	.outline-content__pull-img,
	.outline-content__content__side .widget-Sprout-Widgets-MapWidget {
		margin: -128px 0 35px -1px;
	}
	.outline-content__content__text {
		padding: 184px 90px 53px;
	}
	.outline-content__content {
		margin-top: -108px;
	}
	.outline-content__content__side__content {
		padding: 0 0 25px 35px;
	}
}


/* ---- Image Title Content ---- */
.section--image-title-content {
	position: relative;
	padding-top: 0;
}

.section--image-title-content .container {
	position: relative;
	z-index: 1;
}

.image-title-content__img {
	display: none;
}

/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {
	.image-title-content__img {
		display: block;
		height: 380px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	.image-title-content__title-container {
		pointer-events: none;
	}

	.image-title-content__title-wrap {
		transition: opacity 350ms ease;
	}
	.map-clicked .image-title-content__title-wrap {
		opacity: 0;
	}
}

/* BP 3 */
@include screen($bp992) {
	.image-title-content__img {
		height: 470px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.image-title-content__img {
		height: 580px;
	}
}


/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
	max-width: 1000px;
}

.mfp-ajax-holder .mfp-content {
	background-color: #fff;
	margin: 32px auto 72px;
	padding: 16px;
}

.mfp-wrap .mfp-ajax-holder .mfp-content {
	width: calc(100% - 24px);
}

.mfp-content button.mfp-close {
	top: -8px;
	right: -8px;
}

.mfp-content button.mfp-close:first-child {
	display: block;
	top: 40px;
	/* sits beneath the mobile menu */
    position: fixed;
	right: 26px;
	background-color: $color-secondary;
	color: #FFF;
	opacity: 1;
	box-shadow: 0 0 12px rgba(0,0,0,0.33);
}

/* hide the header and footer for popup pages so it seems like it's only showing page content */
.mfp-ajax-holder .mfp-content #header,
.mfp-ajax-holder .mfp-content .section--pre-footer,
.mfp-ajax-holder .mfp-content #footer {
	display: none;
}

/* BP 1 */
@media screen and (min-width: 600px) {
	.mfp-ajax-holder .mfp-content {
		padding: 50px;
	}
	.mfp-wrap .mfp-ajax-holder .mfp-content {
		width: 88%;
		max-width: 1000px; /* 900px container width + 100px content padding */
	}
	.mfp-content button.mfp-close:first-child {
		right: calc(6% + 6px);
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.mfp-wrap .mfp-container {
		top: 113px;
	}

	.mfp-content button.mfp-close:first-child {
		top: 113px;
	}

	.mfp-content .mainbar {
		/* overriding: .col-md-8 */
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		max-width: 100%;
	}

	.mfp-content .sidebar {
		/* overriding: .col-md-4 */
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		max-width: 100%;
	}

	.header-scrolled .mfp-wrap #wrap {
		margin-top: 0;
	}

	.mfp-content .croc-slider-list {
		/* remove indulgent and disruptive padding */
		padding-left: 0;
	}

}

/* BP 3 */
@include screen($bp992) {
	.mfp-wrap .mfp-container {
		top: 126px;
	}

	.mfp-content button.mfp-close:first-child {
		top: 126px;
	}

	.header-scrolled .mfp-content button.mfp-close:first-child,
	.header-scrolled .mfp-wrap .mfp-container {
		top: 78px;
	}

	.header-scrolled .mfp-wrap #wrap {
		margin-top: 0;
	}

	.mfp-content .croc-slider-list {
		/* remove indulgent and disruptive padding */
		padding-left: 50px;
	}
}

/* mfp-container max width breakpoint */
@media screen and (min-width: 1153px) {
	.mfp-content button.mfp-close:first-child {
		right: calc((100vw - 1000px) / 2);
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.mfp-wrap .mfp-ajax-holder .mfp-content {
		width: 100%;
		max-width: 1210px; /* 1210px container width */
	}

	.mfp-wrap .mfp-container {
		top: 144px;
	}

	.mfp-content button.mfp-close:first-child {
		top: 144px;
		right: calc((100vw - 1210px) / 2);
	}

	.header-scrolled .mfp-content button.mfp-close:first-child,
	.header-scrolled .mfp-wrap .mfp-container {
		top: 96px;
	}

	.mfp-content .mainbar {
		/* simulating: .col-lg-8 */
		-ms-flex-preferred-size: 66.667%;
		flex-basis: 66.667%;
		max-width: 66.667%;
	}

	.mfp-content .sidebar {
		/* simulating: .col-lg-4  */
		-ms-flex-preferred-size: 33.333%;
		flex-basis: 33.333%;
		max-width: 33.333%;
	}
}

/* ---- Thumbnail slider ---- */
.section--thumbnail-slider {
	padding-top: 0;
}

/* Slider list */
.thumbnail-slider-list {
	list-style: none;
	padding: 0;
	margin: 0 0 35px;
	display: none;
}
.thumbnail-slider__item {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.slick-slide.thumbnail-slider__item,
.thumbnail-slider__item {
	height: 350px;
	outline: none;
}


/* Slider nav list */
.thumbnail-slider-list-nav {
	list-style: none;
	padding: 0;
	margin: 0 0 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	background: #ececec;
}
.thumbnail-slider-list-nav .slick-track {
	margin: 0 auto;
	padding: 21px 0;
}
.thumbnail-slider-nav__item {
	position: relative;
	cursor: pointer;
	transition: transform 200ms ease;
	-ms-flex: 1 1 50%;
	    flex: 1 1 50%;
	max-width: 50%;
}
.thumbnail-slider-nav__item__img {
	width: 100%;
}


/* BP 1 */
@media screen and (min-width: 600px) {

}

/* BP 2 */
@media screen and (min-width: 769px) {
	.thumbnail-slider-nav__item {
		width: 155px;
	}
	.slick-slide.thumbnail-slider__item,
	.thumbnail-slider__item {
		height: 540px;
	}
	.thumbnail-slider-list-nav {
		margin: -90px 0 30px;
		background: none;
		display: none;
	}
	.thumbnail-slider-list-nav.slick-slider {
		display: block;
	}
	.slick-slide.thumbnail-slider-nav__item,
	.thumbnail-slider-nav__item {
		margin: 0 15px;
	}
	.thumbnail-slider-list {
		display: block;
	}
	.thumbnail-slider-nav__item:before {
		content: " ";
		background-color: rgba(0,0,0,0.4);
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
	.thumbnail-slider-nav__item:after {
		content: "\e90e";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		color: #FFF;
		display: block;
		text-align: center;
		font-size: 62px;
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: -31px;
		z-index: 5;
	}
	.thumbnail-slider-nav__item:before,
	.thumbnail-slider-nav__item:after {
		transition: all 200ms ease;
	}

	.thumbnail-slider-nav__item:hover:before {
		opacity: 1;
	}
	.thumbnail-slider-nav__item:hover:after {
		animation: fadeInShrink 200ms ease;
		animation-fill-mode: forwards;
	}
	.slick-slide.thumbnail-slider-nav__item,
	.thumbnail-slider-nav__item {
		outline: none;
		box-shadow: 0 0 8px rgba(0,0,0,0.2);
	}
	.thumbnail-slider-nav__item.slick-current {
		transform: scale(1.1);
	}

}

/* BP 3 */
@include screen($bp992) {
	.thumbnail-slider-list-nav {
		margin: -83px 0 50px;
	}
	.slick-slide.thumbnail-slider__item,
	.thumbnail-slider__item {
		height: 720px;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {
	.slick-slide.thumbnail-slider__item,
	.thumbnail-slider__item {
		height: 810px;
	}
	.thumbnail-slider-list-nav {
		margin: -106px 0 80px;
	}
}

/* ---- Operator map ---- */
#operator-map, #tour-map {
	height: 300px;
}

/* BP 2 */
@media screen and (min-width: 769px) {
	#operator-map, #tour-map {
		height: 450px;
	}
}

/* BP 3 */
@include screen($bp992) {
	#operator-map, #tour-map {
		height: 750px;
	}
}

/* ---- Tour map ---- */

.tours-wrap {
    min-height: 200px;
}
.tour-map-content {
    padding: 17px 20px;
    text-align: center;
    font-family: $accent-font;
    font-weight: 300;

}
.tour-map-title {
    font-size: 17px;
    margin: 0 0 7px;
}
.tour-map-description {
    text-align: center;
    font-size: 13px;
    margin: 0;
}
.tour-map-description img {
    margin: auto auto 20px;
}
.tour-map-button {
    padding: 12px 20px 14px;
    background-color: $color-primary;
    color: #FFF;
    text-decoration: none;
    display: block;
    text-transform: lowercase;
    font-family: $primary-font;
    font-size: 15px;
    text-align: center;
    line-height: 1.2;
}
.tour-map-button:hover {
    color: #fff;
    background-color: #cd5d0e;
}
.tours-wrap {
    position: relative;
}
.tours-loading,
.tours-empty {
    z-index: 8;
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    top: -2px;
}
.tours-loading {
    z-index: 99;
}
.fieldset-input.button {
    font-size: 1.6rem;
    padding: 8px 13px 13px;
}
#tour-map-wrap {
	position: relative;
	z-index:1;
}
#tour-map-wrap.map-locked {
    position:fixed;
}
#tour-map-wrap.map-locked.map-locked-footer {
    position:absolute;
}
@media screen and (min-width: 1300px) {
	.tours-wrap {
		padding-right: 5px;
	}
}



/* ---- Switch tabs ---- */
.switch-tab-nav {
	list-style: none;
	padding: 0;
	margin: 0 0 20px;
}
.switch-tab-nav__item {
	display: inline;
}
.switch-tab-nav__item__button {
	margin-top: 0;
	margin-right: 4px;
}

.switch-tab {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.switch-tab.switch-tab--active {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: relative;
	width: auto;
	animation: fadeInUp 500ms ease;
	animation-fill-mode: forwards;
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.switch-tab-nav {
		margin: 0 0 30px;
	}
}

/* BP 3 */
@include screen($bp992) {
	.switch-tab-nav {
		margin: 0 0 40px;
	}
}


/* ------------------------------------------------------
**** Widgets

Widgets & Modules that ship with sprout.
If they don't they belong in seperate partial file

------------------------------------------------------ */


/* ---- Related Links ---- */
.widget-RelatedLinks {
	margin-bottom: 25px;
}
.widget-RelatedLinks ul {
	padding: 0;
	margin:  0;
	list-style: none;
}
.widget-RelatedLinks a {
	text-decoration: none;
	display: block;
	color: $color-secondary;
	padding: 2px 0;
}

.widget-RelatedLinks a:hover {
	color: $color-grey-03;
}

/* Depth 1 */
.widget-RelatedLinks li.depth1 {
	margin: 0 0 3px;
}
.widget-RelatedLinks li.depth1.on a {
	font-weight: bold;
}


/* ---- Simple Image Gallery ---- */
.widget-GallerySimple {
	margin: 15px -10px;
}

.widget-GallerySimple h3 {
	padding: 10px;
}

.widget-GallerySimple .gallery-simple-thumb {
	width: 33.333%;
	padding: 10px;
	float: left;
}
.widget-GallerySimple .gallery-simple-thumb img {
	width: 100%;
}

/* ---- Children Page Gallery ---- */
.children-gallery-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.children-gallery-list:before,
.children-gallery-list:after {
	content: " ";
	display: table;
}

.children-gallery-list:after {
	clear: both;
}

.widget-ChildrenGallery .children-gallery-list-item {
	width: 48%;
	float: left;
	margin: 0 0 20px 4%;
	position: relative;
}

.widget-ChildrenGallery .children-gallery-list-item-link {
	display: block;
	text-decoration: none;
	color: inherit;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover {
	text-decoration: underline;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
	margin-bottom: 9px;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
	opacity: 0.9;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
	margin: 0;
}

.widget-ChildrenGallery .children-gallery-list-item-anchor {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


@media screen and (max-width: 600px) {
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(2n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: 601px) and (max-width: 730px) {
	.widget-ChildrenGallery .children-gallery-list-item {
		width: 30.666666%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: 731px) {
	.widget-ChildrenGallery .children-gallery-list-item {
		width: 22%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(4n+1) {
		margin-left: 0;
		clear: left;
	}
}



/* ---- Sponsors module ---- */

.sponsors-list__item-link {
	text-decoration: none;
}

.sponsors-list__img-wrap {
	width: 100%;
	border: 1px solid #ccc;
	padding: 8px;
	position: relative;
	margin-bottom: 8px;
	transition: border-color 250ms ease-in-out;
}

.sponsors-list__item-link:hover .sponsors-list__img-wrap {
	border: 1px solid #444;
}

.sponsors-list__img-wrap:before {
	content: "";
	display: block;
	padding-top: 100%; 	/* initial ratio of 1:1*/
}

.sponsors-list__img-wrap__vert-center {
	position: absolute;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	text-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
}

.sponsors-list-item__image {
	display: inline-block;
	margin: 0 auto;
	max-height: 100%;
}




/* ---- Order Form ---- */
.widget-OrderForm .product {
	width: 30%;
	margin-right: 5%;
	float: left;
}
.widget-OrderForm .product:nth-child(3n+3) {
	margin-right: 0;
}

/* ---- Videos ---- */
.tinymce-media-iframe,
.widget-Video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

iframe {
	border: 0;
}

.tinymce-media-iframe {
	margin-bottom: 16px;
}


/* ---- reCAPTCHA ---- */
.g-recaptcha {
	padding-bottom: 15px;
}


/* ---- Sidebar ---- */
.sidebar {
	margin-top: 25px;
}
.sidebar .widget {
	margin-bottom: 35px;
}
.sidebar .widget-title {
	font-size: 1.6rem;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0 0 14px;
	font-family: $primary-font;
	position: relative;
	color: $color-grey-03;
}
.sidebar .widget-Sprout-Widgets-MapWidget {
	max-width: 170px;
	display: none;
}



/* BP 1 */
@media screen and (min-width: 600px) {
	.sidebar .widget-Sprout-Widgets-MapWidget {
		display: block;
	}
}

/* BP 2 */
@media screen and (min-width: 769px) {
	.sidebar .widget-Sprout-Widgets-MapWidget {
		max-width: none;
	}
}

/* BP 3 */
@include screen($bp992) {
	.sidebar {
		margin-top: 0;
	}
}

/* BP 4 */
@media screen and (min-width: 1300px) {

}


/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */

@media print {
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: h5bp.com/s */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/* Don't show links for images, or javascript/internal links */

	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}


#infowindow-content {
	background-color: #fff;
	max-width: 200px;
	position: relative;
	padding: 0;
}

/* bottom arrow element */
#infowindow-content:before {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	transform-origin: 0% 0%;
	transform: rotate(45deg);
	right: 48%;
	bottom: -3px;
	background: $color-primary;
}
#infowindow-content .slick-next, .slick-prev { z-index: 1;}
#infowindow-content .slick-next {
	right: -10px;
}
#infowindow-content .slick-prev {
	left: -10px;
}
#infowindow-content .ib-header {
	background-color: $color-primary;
	height: 60px;
	text-align: center;
}
	#infowindow-content .ib-header span {
		color: #fff;
		margin: auto;
	}
#infowindow-content div.duration {
	width: 100%;
	padding: 5px 0;
	text-align: center;
	background: #B04A00;
	color: #fff;
}
/* close button */
.infoBox > img{
	position: absolute!important;
	top: 3px;
	right: 3px;
	z-index: 2;
}
#infowindow-content .tour-map-button {
	padding: 8px 20px 10px;
}
#infowindow-content .ib-footer a.tour-map-button:hover {
	text-decoration: underline;
}
#infowindow-content .ib-footer .tour-map-button:after {
	content: " \00BB";
}
