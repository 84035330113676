
.popup-box-slider-wrap {
    position: relative;
}

.container--popup-box-slider-arrows {
    position: relative;
}

.popup-box-slider-arrows {
    position: absolute;
    z-index: 10;
    top: 0;
    left: calc(-.75 * var(--slick-arrow-width));
    right: calc(-.75 * var(--slick-arrow-width));

    @include screen($bp600) {
        left: calc(-1.5 * var(--slick-arrow-width));
        right: calc(-1.5 * var(--slick-arrow-width));
    }

    @include screen($bp769) {
        left: calc(-1.12 * var(--slick-arrow-width));
        right: calc(-1.12 * var(--slick-arrow-width));
    }

    @include screen(50em) { /* 800px */
        left: calc(-1.5 * var(--slick-arrow-width));
        right: calc(-1.5 * var(--slick-arrow-width));
    }

    @include screen($bp992) {
        left: calc(-1.25 * var(--slick-arrow-width));
        right: calc(-1.25 * var(--slick-arrow-width));
    }

    @include screen($bp1600) {
        left: calc(-2 * var(--slick-arrow-width));
        right: calc(-2 * var(--slick-arrow-width));
    }

    .slick-arrow {
        height: 420px;
    }
}

.popup-box-slider {
    @include list-reset;
    margin: 0;

    &__item {
        text-align: center;
    }

    // replicating the container
    .slick-list {
        @include container;
        overflow: visible;
    }
    .popup-box-slider__item {
        transition: opacity 1s ease-in-out;
        opacity: 1;
        height: 420px;

    }
    .popup-box-slider__item[aria-hidden="true"] {
        opacity: .20;
        pointer-events: none;
    }

    .popup-box-list__item__link:hover {
        height: 460px;
    }
}
