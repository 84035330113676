/* -----------------------------
Visibility Utilities
------------------------------*/

@mixin vis-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin vis-hidden-reset() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}


/* -----------------------------
Clearfix
------------------------------*/
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

/* -----------------------------
Container
------------------------------*/
@mixin container() {
    width: 88%;
    margin: 0 auto;

    /* BP 1 */
    @include screen($bp600) {
        width: 500px;
    }

    /* BP 2 */
    @include screen($bp769) {
        width: 700px;
    }

    @include screen($bp992) {
        width: 92%;
    }

    @include screen($bp1600) {
        max-width: calc(1600px - 8%);
    }

}


/* -----------------------------
SVG
------------------------------*/
@mixin svg-contents() {
    path,
    circle,
    ellipse,
    rect,
    line,
    polygon {
        @content;
    }
}


/* -----------------------------
List Mixins
------------------------------*/
@mixin list-reset() {
    list-style-type: none;
    padding-left: 0;
}

