



/* ---- Sliders --- */

.js-tour-video-slider {
    margin-top: 50px;
    padding: 0;
}

.js-tour-video-slider .slick-next {
    position: absolute;
    bottom: 0;
    z-index: 2;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: rgba(64,64,65,0.8);
    color: #FFF;
    padding: 0;
    width: 38px;
    height: 38px;
    text-indent: -9999px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    top: 45%;
    right: 0;
}

.js-tour-video-slider .slick-next:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-indent: 0;
    position: absolute;
    top: 5px;
    left: 7px;
    font-size: 3.2rem;
    content: "\e901";
}

.js-tour-video-slider .slick-prev {
    position: absolute;
    bottom: 0;
    z-index: 2;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: rgba(64,64,65,0.8);
    color: #FFF;
    padding: 0;
    width: 38px;
    height: 38px;
    text-indent: -9999px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    top: 45%;
    left: 0;
}

.js-tour-video-slider .slick-prev:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-indent: 0;
    position: absolute;
    top: 5px;
    left: 7px;
    font-size: 3.2rem;
    content: "\e903";
}


/* BP 3 */
@include screen($bp992) {
    .koala-slider-list .slick-dots button,
    .croc-slider-list .slick-dots button {
        width: 14px;
        height: 14px;
    }
}

/* ---- Koala slider & banner ---- */
.section--koala-slider,
.section--koala-banner {
    padding-top: 0;
    padding-bottom: 0;
}
.section--koala-banner {
    position: relative;
}
.section--koala-banner .container {
    z-index: 1;
}

.section--koala-slider.section--koala-slider--small .koala-slider-list,
.section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
    height: 300px;
}
.koala-slider-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.koala-slider-list__item {
    position: relative;
    height: 100%;
}
.koala-slider-list__item__content,
.koala-banner__content {
    position: relative;
    z-index: 1;
    height: 100%;
    background-color: rgba(61,68,66,0.5);
}

.koala-slider-list__item__content__inner {
    padding: 80px 6%;
}
.koala-banner__content__inner {
    padding: 40px 155px 80px 96px;
}
.koala-slider-list__item__title,
.koala-banner__title {
    font-size: 2.5rem;
    margin: 0 0 20px;
    line-height: 1;
}
.koala-slider-list__item__img,
.koala-banner__img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.koala-slider-list__item__title:last-child,
.koala-banner__title:last-child {
    margin-bottom: 0;
}

/* Photo credit */
.koala-slider-list__item__credit {
    margin: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: rgba(255,255,255,0.9);
    text-shadow: 0 0 7px #000;
}

/* Photo author */
.koala-slider-list__item__author {
    margin: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: rgba(255,255,255,0.9);
    text-shadow: 0 0 7px #000;
}


/* Slider dots */
.koala-slider-list .slick-dots {
    bottom: 30px;
    left: 51px;
}

/* Button */
.section--koala-slider--button .koala-slider-list .slick-dots {
    bottom: 79px;
    left: 229px;
}
.koala-slider-list__item:nth-child(1n+2) {
    display: none;
}

/* TEMP */
.koala-slider-list__item__img,
.koala-banner__img {
    background-image: url(../images/temp/koala-slider-bg.jpg)
}
.koala-slider-list__item--2 .koala-slider-list__item__img {
    background-image: url(../images/temp/koala-slider-bg2.jpg)
}
.koala-slider-list__item--3 .koala-slider-list__item__img {
    background-image: url(../images/temp/koala-slider-bg3.jpg)
}
.koala-slider-list__item--4 .koala-slider-list__item__img {
    background-image: url(../images/temp/koala-slider-bg4.jpg)
}

/* Js */
.koala-slider-list .slick-list {
    height: 100%;
    overflow: visible;
}
.koala-slider-list .slick-track {
    height: 100%;
}
.koala-slider-list .koala-slider-list__item {
    outline: none;
}

/* No js */
.no-js .koala-slider-list__item {
    display: none;
}
.no-js .koala-slider-list__item:first-child {
    display: block;
}

/* from price */
.koala-slider-list__item__from {
    font-family: $primary-font;
    font-size: 1.2rem;
    text-transform: uppercase;
}
.koala-slider-list__item__from__price {
    font-size: 1.8rem;
    display: inline-block;
    font-weight: bold;
}

/* Extra BP */
@media screen and (min-width: 400px) {
    .koala-slider-list__item__content,
    .koala-banner__content {
        width: 350px;
    }
    .section--koala-slider.section--koala-slider--small .koala-slider-list,
    .section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
        height: 320px;
    }
}

/* BP 1 */
@media screen and (min-width: 600px) {

    .koala-slider-list,
    .koala-banner-wrapper {
        height: 360px;
    }
    .section--koala-slider.section--koala-slider--small .koala-slider-list,
    .section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
        height: 300px;
    }
    .koala-slider-list__item__content,
    .koala-banner__content {
        width: 400px;
    }
    .koala-slider-list__item__title,
    .koala-banner__title {
        font-size: 3rem;
    }
    .koala-slider-list__item__content__inner {
        padding: 40px 40px 62px;
    }
    .koala-slider-list__item__content__inner,
    .koala-banner__content__inner {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    /* from price */
    .koala-slider-list__item__from {
        font-size: 1.4rem;
        /* [02] */
    }
    .koala-slider-list__item__from__price {
        font-size: 2.2rem;
    }
}

/* BP 2 */
@media screen and (min-width: 769px) {
    .koala-slider-list,
    .koala-banner-wrapper {
        height: 432px;
    }
    .section--koala-slider.section--koala-slider--small .koala-slider-list,
    .section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
        height: 360px;
    }
    .koala-slider-list__item__content__inner {
        padding: 40px 85px 62px 50px;
    }
    .koala-banner__content__inner {
        padding: 40px 155px 80px 96px;
    }
    .koala-slider-list__item__title,
    .koala-banner__title {
        font-size: 4.2rem;
        margin-bottom: 30px;
    }
    /* from price */
    .koala-slider-list__item__from {
        font-size: 1.6rem;
        /* [02] */
    }
}

/* BP 3 */
@include screen($bp992) {
    .koala-slider-list__item__content,
    .koala-banner__content {
        width: 530px;
    }
    .koala-slider-list__item__content__inner {
        padding: 40px 115px 102px 66px;
    }
    .koala-banner__content__inner {
        padding: 40px 155px 80px 96px;
    }
    .section--koala-slider--button .koala-slider-list .slick-dots {
        bottom: 121px;
        left: 290px;
    }
    .koala-slider-list__item__title,
    .koala-banner__title {
        font-size: 5.2rem;
    }
    .section--koala-slider--button .koala-slider-list .slick-dots {
        bottom: 121px;
        left: 277px;
    }
    .koala-slider-list,
    .koala-banner-wrapper {
        height: 540px;
    }
    .section--koala-slider.section--koala-slider--small .koala-slider-list,
    .section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
        height: 432px;
    }
    .koala-slider-list .slick-dots {
        bottom: 58px;
        left: 70px;
    }
}

/* BP 4 */
@media screen and (min-width: 1300px) {
    .koala-slider-list__item__content,
    .koala-banner__content {
        width: 646px;
    }
    .koala-slider-list__item__content__inner {
        padding: 40px 155px 150px 96px;
    }
    .koala-banner__content__inner {
        padding: 40px 155px 80px 96px;
    }
    .koala-slider-list,
    .koala-banner-wrapper {
        height: 720px;
    }
    .section--koala-slider.section--koala-slider--small .koala-slider-list,
    .section--koala-banner.section--koala-banner--small .koala-banner-wrapper {
        height: 540px;
    }
    .koala-slider-list__item__title,
    .koala-banner__title {
        font-size: 6.2rem;
        margin: 0 0 40px;
    }
    .section--koala-slider--button .koala-slider-list .slick-dots {
        bottom: 168px;
        left: 320px;
    }
    .koala-slider-list .slick-dots {
        bottom: 78px;
        left: 96px;
    }
}

