
.feature-box-grid {
    @include list-reset;
    display: grid;
    gap: $spacing*2;

    @include screen($bp600) {
        grid-template-columns: repeat(2, 1fr);

    }

    @include screen($bp992) {
        gap: $spacing*5;
    }

    @include screen($bp1200) {
        grid-template-columns: repeat(3, 1fr);

    }

    &__item {
        &--odd-extra-child {
            display: none;

            @media screen and (min-width: $bp600) and (max-width: $bp1199) {
                display: block;
            }
        }
    }

}

